const baseURL = `https://rebuyengine.com/api/v1`;
const apiKey = process.env.NEXT_PUBLIC_REBUY_API_KEY;

const rebuyRecommendations = {
    fetchRelated: async (shopify_product_ids = []) => {
        let params = new URLSearchParams({
            key: apiKey,
            shopify_product_ids: shopify_product_ids.join(',')
        }).toString();
        let productIds = (await fetch(`${baseURL}/products/recommended?${params}`)
            .then(response => response.json())
            .then(data => { return data }))
            .map((item, i) => item.id.toString())

        return productIds
    },
    fetchCustom: async (rulesetId) => {
        let params = new URLSearchParams({
            key: apiKey
        }).toString();
        let productIds = (await fetch(`${baseURL}/custom/id/${rulesetId}?${params}`)
            .then(response => response.json())
            .then(data => data?.data ?? [] ))

        return productIds
    },
}

export default rebuyRecommendations