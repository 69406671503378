import DesktopNavigation from './DesktopOld/DesktopNavigation'
import MobileNavigation from './MobileOld/MobileNavigation'
import getColorStyle from 'lib/getColorStyle'
import { useRouter } from 'next/router'
import linkResolver from 'lib/linkResolver'
import { useEffect } from 'react'
import { useDiscountFromURL } from 'context/globalStore'

export default function Navigation({
   homepageCta,
   desktopPrimaryMenu,
   desktopSecondaryMenu,
   globals,
   desktopPrimaryMenuV2,
   desktopSecondaryMenuV2,
   promoBar,
   mobilePrimaryMenu,
   mobileSecondaryMenu,
   mobilePrimaryMenuV2,
   mobileSecondaryMenuV2,
   mobileFixedMenu,
   bgColorConfig,
   searchConfig,
   isBannerHidden,
   isNoNavigation
}) {
  const { asPath: currentPath } = useRouter()
  const bgColorStyle = getBgColorStyle(bgColorConfig, currentPath)
  const router = useRouter()
  const { setDiscountFromURL } = useDiscountFromURL()

  useEffect(() => {
    const discount = router.query?.discount
    if (discount) setDiscountFromURL(discount)
  }, [router.query, setDiscountFromURL])

  return (
    <>
      <DesktopNavigation
        homepageCta={homepageCta}
        desktopPrimaryMenu={desktopPrimaryMenu}
        desktopSecondaryMenu={desktopSecondaryMenu}
        bgColorStyle={bgColorStyle}
        desktopPrimaryMenuV2={desktopPrimaryMenuV2}
        desktopSecondaryMenuV2={desktopSecondaryMenuV2}
        globals={globals}
        searchConfig={searchConfig}
        isBannerHidden
        isNoNavigation={isNoNavigation}
      />
      <MobileNavigation
        mobilePrimaryMenu={mobilePrimaryMenu}
        mobileSecondaryMenu={mobileSecondaryMenu}
        mobilePrimaryMenuV2={mobilePrimaryMenuV2}
        mobileSecondaryMenuV2={mobileSecondaryMenuV2}
        mobileFixedMenu={mobileFixedMenu}
        bgColorStyle={bgColorStyle}
        globals={globals}
        searchConfig={searchConfig}
        isBannerHidden
        isNoNavigation={isNoNavigation}
      />
    </>
  )
}

function getBgColorStyle(config, path) {
  const defaultBg = { backgroundColor: '#ffffff' }

  if (!config) {
    return defaultBg
  }

  config = config.map((x) => ({
    link: linkResolver({ ...x.link, _type: 'internalLink' }),
    color: x.color,
  }))
  const bg = config.find((x) => path.includes(x.link))

  if (!bg) {
    return defaultBg
  } else {
    return { backgroundColor: getColorStyle(bg.color) }
  }
}
