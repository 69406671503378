import { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import Loader from '../Loader/Loader'
import {
  useIsCartOpen,
  useCartItems,
  useIsUpdating,
  useCartTotals,
  useIsFreeShippingUnlocked,
  useNavigateToCheckout,
  useStore,
  useAutoDiscount,
  useAddCartItems,
  useSubscriptionState,
  useRemoveCartItems,
  useApplyTieredPromotion,
  useUpdateCartItems,
} from 'context/globalStore'
import CartHeader from './Components/CartHeader'
import CartPromo from 'components/CartPromo'
import CartLineItem from './Components/CartLineItem'
import CartDonation from 'components/CartDonation'
import CartExtraSell from 'components/CartExtraSell'
import CartBestSellersCarousel from 'components/CartBestSellersCarousel'
import CartGiftMessageForm from 'components/CartGiftMessageForm'
import CartUpsellCarousel from 'components/CartUpsellCarousel'
import useGetAmountForCartProgress from '/hooks/useGetAmountForCartProgress'
import Button from 'components/Button'
import { add, remove } from 'martha'
import cx from 'classnames'
import { useRect } from '@reach/rect'
import * as analytics from 'lib/analytics'
import { shopify } from 'lib/shopify'
import useMedia from 'use-media'
import { ARTICLES_PER_PAGE, screens } from 'lib/constants'
import { useAllProducts } from 'context/globalStore'
import usePageScrollLock from 'hooks/usePageScrollLock'
import useGorgiasChatPositioning from 'hooks/useGorgiasChatPositioning'
import { usePromoConfig } from 'hooks/usePromoConfig'
import { getPriceAsNumber, formatNumberAsPrice } from 'lib/formatPrice'
import useABVariation from 'hooks/useABVariation'
import useCartUpsellItems from '/hooks/useCartUpselIItems'
import addToCartAsync from './helpers/addGiftToTheCart'
import { useRouter } from 'next/router'
import getProductDetails from './helpers/getProductDetails'
import { getCurrencyCode } from '../helpers/getCurrencyCode'
import isAvailableOnPageLevel from './helpers/isPromoAvailableOnPageLevel'
import { FREE_GIFT_PROMO_TYPE } from './constants'
import { useUpdateExtraFreeProduct } from './helpers/extraFreeProductUtils'

function SubTotal({
  activeDiscount,
  subTotalAggregateAfterOverride = null,
  subTotalAggregateBeforeOverride = null,
}) {
  const { store } = useStore()
  const { total, nonDiscountTotal } = useCartTotals()

  const discountedTotal = useMemo(() => {
    const {
      checkout: { totalPrice },
    } = store
    return totalPrice?.amount ? `${parseFloat(totalPrice?.amount)?.toFixed(2)?.toString()}` : ''
  }, [store])

  const config = usePromoConfig()

  const discountAmount =
    config?.enableAutoDiscount && store.checkout.discountApplications.length > 0

  function formatNonDiscountTotal(input) {
    const amount = parseFloat(input.replace('$', ''))
    if (isNaN(amount)) {
      return 'Invalid input'
    }

    return amount.toFixed(2)?.toString()
  }
  function extractAndParsePrice(priceString) {
    const numericPart = priceString.match(/[\d\.]+/)?.[0]
    return parseFloat(numericPart)
  }

  return (
    <>
      <span
        className={cx('mr-5 line-through font-medium', {
          hidden: !discountAmount
            ? store.countryCode === 'US'
              ? formatNonDiscountTotal(nonDiscountTotal) === discountedTotal
              : extractAndParsePrice(nonDiscountTotal).toFixed(2) ===
              extractAndParsePrice(discountedTotal).toFixed(2)
            : subTotalAggregateAfterOverride === subTotalAggregateBeforeOverride,
        })}
        style={{ color: '#A1B1BE' }}
      >
        {nonDiscountTotal}
      </span>
      {
        <span
          className="font-medium"
          style={{
            color:
              discountAmount && subTotalAggregateAfterOverride !== subTotalAggregateBeforeOverride
                ? config?.promoTheme?.primaryColor?.hex ?? 'rgb(51, 105, 255)'
                : undefined,
          }}
        >
          {subTotalAggregateAfterOverride || discountedTotal}
        </span>
      }
    </>
  )
}

export default function Cart({ cart, globals, collection, cartPromo }) {
  const isScreenSmall = useMedia({ minWidth: screens.s })
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const [toggleLoader, setToggleLoader] = useState(false)
  const footerRef = useRef()
  const footerRect = useRect(footerRef)
  const headerRef = useRef()
  const headerRect = useRect(headerRef)
  const hasMounted = useRef(false);
  const { isFreeShippingUnlocked } = useIsFreeShippingUnlocked()
  const { isCartOpen, setIsCartOpen } = useIsCartOpen()
  const { filteredCartCount, paidItemsCartCount, filteredLineItems, lineItems } = useCartItems()
  const { isUpdating } = useIsUpdating()
  const { total, nonDiscountTotal, subtotalPriceAmount } = useCartTotals()
  const [isFocusTrapActive, setIsFocusTrapActive] = useState(isCartOpen)
  const { navigateToCheckout, isNavigatingToCheckout } = useNavigateToCheckout()
  const { autoDiscount, isAutoDiscountGiftAdded, getDiscountedGiftItemFromCart, setAutoDiscount } =
    useAutoDiscount()
  const { store, setStore } = useStore()
  const addToCart = useAddCartItems()
  const applyTiredPromotion = useApplyTieredPromotion()
  const removeFromCart = useRemoveCartItems()
  const { isSubscriptionAllowed } = useSubscriptionState()
  const [extraSellVisibility, setExtraSellVisibility] = useState(true)
  const [bestSellerVisibility, setBestSellerVisibility] = useState(true)
  const { allProducts } = useAllProducts()
  const [activeDiscount, setActiveDiscount] = useState(null)
  const [lineItemPrices, setLineItemPrices] = useState({})
  const [cartUpsellProducts, setCartUpsellProducts] = useState(cart.upsells.products)
  const [removingItem, setRemovingItem] = useState(false)
  const [excludedProductsPrice, setExcludedProductsPrice] = useState(0)
  const config = usePromoConfig()
  const { variation } = useABVariation('mini-cart-upsell')
  const { countryCode } = store
  const router = useRouter()
  const [giftJustAdded, setGiftJustAdded] = useState(false)
  const updateCartItems = useUpdateCartItems()
  const [isTriggerProductInTheCart, setIsTriggerProductInTheCart] = useState(false)

  useEffect(() => {
    const oneProductUpsell = allProducts.find(
      (product) => {
        return product._id ===
          filteredLineItems?.[0]?.variant?.product.id.replace('gid://shopify/Product/', '')
      }
    )
    if (filteredLineItems?.length === 1 && oneProductUpsell?.cartUpsellProducts) {
      const idsForOneProductRecommendations = oneProductUpsell?.cartUpsellProducts?.map(
        (product) => product._ref
      )
      const oneProductRecommendations = allProducts
        .filter((product) => idsForOneProductRecommendations?.includes(product._id))
        .map((product) => ({
          product: product,
          variants: product?.variants?.filter((variant) => variant?.available !== 'false' && variant?.disableForOneTime !== true),
        }))
        .filter((product) => product.variants.length > 0);
      setCartUpsellProducts(oneProductRecommendations)
    } else {
      const filteredUpsellProducts = cart.upsells.products
        .map(({ variants, ...rest }) => ({
          ...rest,
          variants: variants?.filter((variant) => variant?.available !== 'false' && variant?.disableForOneTime !== true),
        }))
        .filter((upsellProduct) => upsellProduct.variants.length > 0);
      setCartUpsellProducts(filteredUpsellProducts);
    }
  }, [total, filteredLineItems?.length])

  useEffect(() => {
    setGiftJustAdded(false)
  }, [router])

  useEffect(() => {
    if (isAvailableOnPageLevel(config, router)) {
      addToCartAsync(
        config,
        filteredLineItems,
        setExcludedProductsPrice,
        allProducts,
        subtotalPriceAmount,
        removingItem,
        addToCart,
        setGiftJustAdded,
        updateCartItems,
        store
      )
    } else if (!config?.enablePromoOnPageLevel) {
      addToCartAsync(
        config,
        filteredLineItems,
        setExcludedProductsPrice,
        allProducts,
        subtotalPriceAmount,
        removingItem,
        addToCart,
        setGiftJustAdded,
        updateCartItems,
        store
      )
    }

    if (config?.promoType === FREE_GIFT_PROMO_TYPE) {
      setAutoDiscount({
        promoCode: config.promoCode,
        enableAutoDiscount: config?.enableAutoDiscount,
      })
    }

    setIsTriggerProductInTheCart(
      config?.gwpIncludedProducts?.some((gwpProduct) =>
        gwpProduct.variants.some((variant) =>
          filteredLineItems?.some((item) => shopify.decode(item.variant.id) === variant.variantId)
        )
      )
    )
  }, [total, filteredLineItems?.length])

  const { addExtraFreeProduct, updateExtraFreeProductsCart } = useUpdateExtraFreeProduct();

  useEffect(() => {
    addExtraFreeProduct(filteredLineItems, addToCart, allProducts, router)
  }, [total, router?.pathname])


  useEffect(() => {
    if (!removingItem && allProducts?.length > 0) {
      updateExtraFreeProductsCart(filteredLineItems, updateCartItems, allProducts, setRemovingItem)
    }
  }, [total, router?.pathname, removingItem, allProducts.length])

  useEffect(() => {
    if (hasMounted.current && filteredLineItems.length === 0) {
      localStorage.setItem('removedExtraFreeProducts', JSON.stringify([]))
    }

    if (filteredLineItems.length > 0) {
      let removedExtraFreeProducts = JSON.parse(localStorage.getItem('removedExtraFreeProducts')) || [];

      removedExtraFreeProducts = removedExtraFreeProducts?.filter(removedItem => {
        return filteredLineItems?.some(item => {
          const isSubscription = item.customAttributes.some(attr => attr.key === 'subscription_id')
          if (removedItem.parent) {
            return removedItem.parent.parentVariantId === item.variant.id && removedItem.parent.isSubscription === isSubscription
          }
          return (
            removedItem.parentProductId === item.variant.id
          )
        })
      }
      );
      hasMounted.current = true;
      localStorage.setItem('removedExtraFreeProducts', JSON.stringify(removedExtraFreeProducts));
    }
  }, [filteredLineItems.length, total, removingItem]);

  const { cartUpsellItems } = useCartUpsellItems(cartUpsellProducts, variation)

  async function handleCheckoutClick(ev) {
    ev.preventDefault()
    analytics.trackButtonClick('Checkout')
    await navigateToCheckout(globals)
  }

  usePageScrollLock(isCartOpen, 600)
  const { updateChatPosition } = useGorgiasChatPositioning()

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (isScreenLarge) {
      if (isCartOpen ) {
        add(document.body, 'overflow-hidden')
        updateChatPosition({
          display: 'none',
        })
      } else {
        remove(document.body, 'overflow-hidden')
        setTimeout(() => {
          updateChatPosition({
            display: 'block',
          })
        }, 500)
      }
    }

    setIsFocusTrapActive(isCartOpen)
  }, [isCartOpen])

  useEffect(async () => {
    const { countryCode } = store
    if (countryCode !== 'US') return
    // if (!isSubscriptionAllowed) return;
    const appliedPromo = await applyTiredPromotion()
    setActiveDiscount(appliedPromo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total])

  function handleClose() {
    analytics.trackCartClick('Close')
    setIsCartOpen(false)
  }

  function getSubtotal() {
    return (
      <>
        {' '}
        {nonDiscountTotal && autoDiscount?.discountApplied ? (
          <span className="mr-5 line-through">{nonDiscountTotal}</span>
        ) : null}{' '}
        {total}{' '}
      </>
    )
  }

  const message1 = 'Taxes calculated at checkout'
  const message2 = 'Taxes and shipping calculated at checkout'
  const message3 = 'Shipping calculated at checkout'
  function getShippingAndTaxLabel() {
    const { countryCode } = store

    let message
    if (countryCode === 'US') message = isFreeShippingUnlocked ? message1 : message2
    else message = message3

    return message
  }

  function getExtraProducts() {
    const crossell = cart.crossell.products
    return crossell
  }

  function getBestSellerProducts() {
    const bestSellers =
      cart.bestSellers && cart.bestSellers.products
        ? cart.bestSellers.products
        : cart.crossell.products
    return bestSellers
  }

  function handleExtraSellVisibility(state) {
    setExtraSellVisibility(state)
  }

  function handleBestSellerVisibility(state) {
    setBestSellerVisibility(state)
  }

  const subTotalAmount = useMemo(() => {
    return filteredLineItems?.reduce(
      (cumm, curr) => {
        const itemAfter = lineItemPrices?.[curr?.id]?.after
        const itemBefore = lineItemPrices?.[curr?.id]?.before
        return {
          before: cumm.before + (itemBefore ? getPriceAsNumber(itemBefore) : 0),
          after: cumm.after + (itemAfter ? getPriceAsNumber(itemAfter) : 0),
        }
      },
      { before: 0, after: 0 }
    )
  }, [filteredLineItems, lineItemPrices])

  const subTotalAggregatedAfter = useMemo(() => {
    return formatNumberAsPrice(subTotalAmount?.after, getCurrencyCode(countryCode))
  }, [subTotalAmount?.after])

  const subTotalAggregatedBefore = useMemo(() => {
    return formatNumberAsPrice(subTotalAmount?.before, getCurrencyCode(countryCode))
  }, [subTotalAmount?.before])

  const amountForProgressbar = useGetAmountForCartProgress(config)

  return cart ? (
    <div
      className={cx('fixed inset-0 z-20', {
        'pointer-events-none': !isCartOpen,
      })}
      inert={!isCartOpen ? '' : null}
    >
      <div className="relative w-full h-full">
        <div
          className={cx(
            'absolute inset-0 bg-black bg-opacity-35 transition ease-in-out-cubic duration-500',
            {
              'opacity-0': !isCartOpen,
            }
          )}
          onClick={handleClose}
        />
        <div
          className={cx(
            'cart-panel flex absolute inset-y-0 left-full max-w-600 w-full bg-alice-blue transition ease-in-out-cubic duration-500',
            {
              'transform-gpu -translate-x-full': isCartOpen,
            }
          )}
        >
          <div className="relative max-w-600 w-full h-full shadow-overlay">
            <CartHeader
              count={paidItemsCartCount}
              text={cart.headerText}
              handleClose={handleClose}
              ref={headerRef}
            />

            {/* Scroll Area */}
            <div
              className="flex flex-col pt-45 s:pt-50 h-full overflow-y-auto overflow-x-hidden z-10 "
              style={{
                paddingBottom: footerRect?.height ? footerRect.height : null,
                paddingTop: headerRect?.height ? headerRect?.height : undefined,
                justifyContent: isScreenSmall ? 'space-between' : 'flex-start',
              }}
            >
              <div>
                {/* Cart Promo (e.g. Free Shipping Meter) */}
                {cart.promo.isEnabled ? (
                  <CartPromo
                    promo={cartPromo?.isEnabled && cartPromo?.component ? cartPromo : cart.promo}
                    subTotalAmount={amountForProgressbar}
                  />
                ) : null}
                {filteredCartCount || toggleLoader ? (
                  <ul
                    className={cx('px-15 s:px-25 space-y-10 pb-10', {
                      'opacity-65': isUpdating,
                    })}
                  >
                    {filteredLineItems?.map((item) => (
                      <CartLineItem
                        globals={globals}
                        key={item.id}
                        item={item}
                        allLineItems={lineItems}
                        showLoader={(e) => setToggleLoader(e)}
                        product={getProductDetails(item, allProducts)}
                        activeDiscount={activeDiscount}
                        setLineItemPrices={(x) =>
                          setLineItemPrices((prev) => ({ ...prev, [item.id]: x }))
                        }
                        filteredLineItems={filteredLineItems}
                        removingItem={removingItem}
                        setRemovingItem={setRemovingItem}
                        totalPrice={total.replace(/[^0-9.]/g, '')}
                        excludedProductsPrice={excludedProductsPrice}
                        giftJustAdded={giftJustAdded}
                        setGiftJustAdded={setGiftJustAdded}
                        isTriggerProductInTheCart={isTriggerProductInTheCart}
                      />
                    ))}
                  </ul>
                ) : (
                  <div className="">
                    <div className="text-center pt-15 max-w-425 mx-auto">
                      <div className="font-medium text-20 leading-24 l:text-16 l:leading-20 mb-20">
                        {cart.empty.overline}
                      </div>

                      <Button link={cart.empty.cta} variant="contained" size="large">
                        {cart.empty.cta.title}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {toggleLoader && (
                <Loader header="Updating..." info="Sit tight, We’re updating your cart!" />
              )}
              {cart?.crossell?.isEnabled ? (
                <div
                  className={cx('s:hidden relative z-10', {
                    block: extraSellVisibility,
                    hidden: !extraSellVisibility,
                  })}
                >
                  <CartExtraSell
                    products={getExtraProducts()}
                    setExtraSellVisibility={handleExtraSellVisibility}
                  ></CartExtraSell>
                </div>
              ) : null}
              {!filteredCartCount ? (
                <div
                  className={cx('relative z-10', {
                    block: bestSellerVisibility,
                    hidden: !bestSellerVisibility,
                  })}
                >
                  {cart?.bestSellers.isEnabled && cart?.bestSellers.products && (
                    <CartBestSellersCarousel
                      products={getBestSellerProducts()}
                      setBestSellerVisibility={handleBestSellerVisibility}
                      header={'Explore Our Bestsellers'}
                      globals={globals}
                    />
                  )}
                </div>
              ) : null}
            </div>
            <div>
              <div ref={footerRef} className="absolute inset-x-0 bottom-0 z-20">
                {filteredCartCount ? (
                  <div className="bg-white shadow-bottom-bar">
                    <div className="pt-12">
                      {cartUpsellItems?.length > 0 && (
                        <CartUpsellCarousel items={cartUpsellItems} globals={globals} />
                      )}
                    </div>
                    <div>
                      <div className="flex items-baseline justify-between text-13 s:text-16 font-medium leading-100 px-30 s:px-45 pb-16">
                        <div className="text-navy-blue-muted">Subtotal</div>
                        <div className="text-navy-blue">
                          <SubTotal
                            activeDiscount={activeDiscount}
                            subTotalAggregateAfterOverride={subTotalAggregatedAfter}
                            subTotalAggregateBeforeOverride={subTotalAggregatedBefore}
                          />
                        </div>
                      </div>
                      {cart.giftMessageEntry.isEnabled ? (
                        <CartGiftMessageForm {...cart.giftMessageEntry} />
                      ) : null}
                    </div>
                    <div className="px-15">
                      <Button
                        variant="contained"
                        size="large"
                        className="w-full text-18"
                        disabled={isNavigatingToCheckout}
                        onClick={handleCheckoutClick}
                      >
                        {isNavigatingToCheckout ? 'Loading…' : 'Checkout'}
                      </Button>
                      <p className="text-11 s:text-13 leading-100 text-navy-blue-muted text-center pt-10 s:pt-15 pb-15 s:pb-20">
                        {getShippingAndTaxLabel()}
                      </p>
                    </div>
                    {cart.donation.isEnabled ? <CartDonation donation={cart.donation} /> : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
