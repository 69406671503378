import { getProductReviewAggregate } from 'lib/okendo'
import { getSanityClient } from 'lib/sanity'
import groq from 'groq'
import chunk from 'lodash.chunk'
import { ARTICLES_PER_PAGE } from 'lib/constants'

const externalLink = groq`{
  url
}`

const internalLinkProjection = groq`
  _type,
  'slug': slug.current,
  _type == 'productLander' => {
    ...product-> {
      'productSlug': slug.current,
    },
  },
  _type == 'page' => {
    'isHomepage': *[_type == 'globals'][0].homepage._ref == @._id,
  }
`
const internalLinkReference = groq`{
  ${internalLinkProjection},
  _type == 'anchor' => {
    'hash': hash.current,
    parent-> {
      ${internalLinkProjection}
    }
  }
}`

const internalLink = groq`{
  reference-> ${internalLinkReference},
  rawLinkOverride
}`

const linkConditions = groq`
  _type == 'externalLink' => ${externalLink},
  _type == 'internalLink' => ${internalLink}
`
const link = groq`{
  ...,
  ${linkConditions},
}`

const siloImage = groq`{
  ...,
  asset->,
}`

const image = groq`{
  ...,
  asset->,
}`

const video = groq`{
  asset->,
}`

const backgroundColorStyle = groq`{
  ...reference-> {
    ...style[0],
  },
}`

const justLinksBlock = groq`{
  ...,
  markDefs[] {
    ...,
    ${linkConditions},
  },
}`

const priceNotation = groq`{
  notations[] {
    enable,
    countries[]-> { countryCode },
    createNotation[] { ... },
    atcButtonPrice
  }
}`

const plpAutoDiscount = groq`{
  enableAutoDiscount,
  autoDiscountAmount,
  autoDiscountText,
}`

const cookieNotice = groq`
  *[_type == 'cookieNotice'] | order(_updatedAt desc)[0] {
    ...,
    content[] ${justLinksBlock},
  }
`

const allCountries = groq`
  *[_type=="country" && countryCode != "OT"]{
    ...,
    countryFlag ${image},
  }
`

const media = groq`{
  ...,
  _type == 'imageAsset' => {
    image ${image},
    hideFromPDP,
    isHoverImage
  }
}`

const responsiveMedia = groq`
  'media': media[0] ${media},
  'desktopMedia': media[1] ${media}
`

const primaryHeroFields = groq`
  'headImageMedia': headImage[0] {
    image ${image}
  },
  'headImageMediaDesktop': headImage[1] {
    image ${image}
  },
  headlineColor ${backgroundColorStyle},
  desktopHeadlineColor ${backgroundColorStyle},
  headlineWrap,
  bodyColor ${backgroundColorStyle},
  desktopBodyColor ${backgroundColorStyle},
  ctaColor ${backgroundColorStyle},
  centerBadge {
    enabled,
    header,
    body,
    text,
    textColor ${backgroundColorStyle},
    primaryColor ${backgroundColorStyle},
    secondaryColor ${backgroundColorStyle},
  },
  cta {
    _type,
    buttonType,
    'link': link[0] ${link},
  },
  ctaFooter[] ${justLinksBlock},
  ctaFooterColor ${backgroundColorStyle},
  emailCapture {
    enabled,
    mailingListId,
    internationalMailingListId,
    buttonText,
    buttonType,
    successHeadline,
    successBody,
    successCta{
      _type,
      buttonType,
      'link': link[0] ${link}
    }
  },
  'layout': layout[0] {
    _type,
    _key,
    fullWidthDesktopAlignment,
    fullWidthOnDesktop,
    desktopOrder,
    mobileOrder,
    isWaveEnabled,
    backgroundStyle,
    'background': backgroundColorStyle ${backgroundColorStyle},
    'backgroundMedia': backgroundMedia[0] ${media},
    gradientStyle,
    align
  },
  miniHero,
  miniHeroHeight,
  ${responsiveMedia}
`

const promoBar = groq`
  *[_type == 'promoBar'] | order(_updatedAt desc)[0] {
    ...,
    background ${backgroundColorStyle},

    banners[] {
      ...,
      showLargerPromoBar,
      background ${backgroundColorStyle},
      linkHoverColor ${backgroundColorStyle},
      textColor ${backgroundColorStyle},
      country-> {
        countryCode,
        currencyCode,
        title,
        countryFlag ${image}
      },
      messages[] {
        ...,
        mobileContent[] ${justLinksBlock},
        desktopContent[] ${justLinksBlock},
        mobileContentLine2[] ${justLinksBlock},
        desktopContentLine2[] ${justLinksBlock},
        popupContent[] ${justLinksBlock},
        popupToggles[] {
          ...,
          content[] ${justLinksBlock}
        }
      },
    },
    "allCountries": ${allCountries}
  }
`

const promoConfig = groq`*[_type == 'promo'] | order(_updatedAt desc)[0] {
  ...,
  promoPresets {
    enableAutoDiscount,
  activePromosArray[] -> {
    ...,
    autoDiscountText, allowOverride, promoType, promoCode, oneTimeDiscountPct, subscriptionDiscountPct, subscribeAndSaveDiscountText, hideStrikeoutPrice,
    enablePromoOnPageLevel,
    enablePromoForCustomerFromActivePage,
    pagesToApplyPromo[] -> {
      slug
    },
    hidePromoPrice,
    autoDiscountCartItemText,
    autoDiscountSubscriptionText,
    autoDiscountText,
    promoTheme {
        accentColor {
            hex, alpha
        },
        primaryColor {
            hex, alpha
        },
        cartBannerColor {
          hex, alpha
        }
    },
    thresholdAmount,
    thresholdAmountForGift,
    includedCollections[] -> {
        slug {
        current
        }
    },
    excludedCollections[] -> {
        slug {
            current
        }
    },
    includedProducts[] -> {
        slug {
           current
        }
    },
    excludedProducts[] -> {
        slug {
          current
        }
    },
    pagesToApplyPromo[] -> {
      slug
    },
    homePageHero {
      ...,
      ${primaryHeroFields},
      countrySpecificHeros[] {
        ...,
        countryCode,
        ${primaryHeroFields}
      }
    },
    pdpPromoBox {
      promoBoxBackgroundColor {
          hex, alpha
      },
      promoBoxForegroundColor {
          hex, alpha
      },
      promoBoxTitle,
      promoBoxBody
    },
    gwpIncludedProducts[] -> {
      title,
      slug {
         current
      },
      variants[] {
        variantId,
        cents,
        ...productVariant-> {
          cents,
          variantId,
          price,
          productId,
          productTitle,
          title,
          variantTitle
        }
      },
      _type == 'productVariant' => {
        ...,
        'product': *[_type == 'product' && references(^._id)][0],
        'variants': [{ ..., ...productVariant-> }],
      },
    },
    quantityForTriggerGiftProduct,
    giftWithPurchase[] -> {
      _type == 'productVariant' => {
        ...,
        'product': *[_type == 'product' && references(^._id)][0],
        'variant': { ..., ...productVariant-> },
      },
    },
    PLPTiles[] {
      _type,
      backgroundColor {
        hex,
      },
      textColor {
        hex,
      },
      layout,
      startIndex,
      collection[] -> { 
        title,
        slug {
          current
        }
      },
      subCollection[] -> { 
        title,
        slug {
          current
        }
      },
      autoDiscountOneTimeText,
      tabletStartIndex,
      headline,
      plpCalloutBody,
      backgroundImage ${image},
      plpCalloutFooter,
      subText,
      link[0] ${link},
      linkColor{
        hex,
      },
      showOnDesktop,
      showOnMobile,
      showOnExtraSection,
    },
    PLPBanners[] {
      _type,
      collection-> { 
        title,
        slug {
          current
        }
      },
      showThickBanner,
      thickBannerBackgroundColor {
        hex
      },
      thickBannerText, 
      thickBannerHeadline,
      thickBannerImage ${image},
      thickBannerLink[0] ${link},
      showThinBanner,
      thinBannerHeadlineColor {
        hex
      },
      thinBannerHeadline,
      thinBannerImage ${image},
    },
    shippingMeterSuccesMessage,
    cartBanner,
    cartSubscriptionToggle,
    useOriginalPriceAsOneTimePriceSlash,
    displayDiscountPrice,
    useOriginalOneTimePriceAsSubPriceSlash,
    discountBadgeText,
    subSettings,
    collectionDiscountToActivate {
      collectionsPriorityList[] -> {
        slug {
            current
        },
        percentageDiscount,
        displayOneTimeDiscountPrice,
        percentageOneTimeDiscount,
      },
    },
    promoBar {
      ...,
      banners[] {
        ...,
        showLargerPromoBar,
        background ${backgroundColorStyle},
        linkHoverColor ${backgroundColorStyle},
        textColor ${backgroundColorStyle},
        country-> {
          countryCode,
          currencyCode,
          title,
          countryFlag ${image}
        },
        messages[] {
          ...,
          mobileContent[] ${justLinksBlock},
          desktopContent[] ${justLinksBlock},
          mobileContentLine2[] ${justLinksBlock},
          desktopContentLine2[] ${justLinksBlock},
          popupContent[] ${justLinksBlock},
          popupToggles[] {
            ...,
            content[] ${justLinksBlock}
          }
        },
      },
      autoplayDuration,
      "allCountries": ${allCountries}
    },
    cartPromo {
      isEnabled,
      ...,
      'freeShippingMeters': component[] {
        _type == "freeShippingMeter" => {
          ...,
        },
      },
      component[0] {
        _type == "freeShippingMeter" => {
          ...,
        },
        _type == "tieredPromo" => {
          countryGroups[] {
            tiers[] {
              title,
              thresholdPrice,
              messageTemplate,
              successMessage,
            },
            countryCode,
          },
        },
        _type == "basicMessage" => {
          ...,
        },
      },
    },
  },
  activePromo -> {
    autoDiscountText, allowOverride, promoType, promoCode, oneTimeDiscountPct, subscriptionDiscountPct, subscribeAndSaveDiscountText, hideStrikeoutPrice,
    enablePromoOnPageLevel,
    enablePromoForCustomerFromActivePage,
    pagesToApplyPromo[] -> {
      slug
    },
    hidePromoPrice,
    autoDiscountCartItemText,
    autoDiscountSubscriptionText,
    autoDiscountText,
    promoTheme {
        accentColor {
            hex, alpha
        },
        primaryColor {
            hex, alpha
        },
        cartBannerColor {
          hex, alpha
        }
    },
    thresholdAmount,
    thresholdAmountForGift,
    includedCollections[] -> {
        slug {
        current
        }
    },
    excludedCollections[] -> {
        slug {
            current
        }
    },
    includedProducts[] -> {
        slug {
           current
        }
    },
    excludedProducts[] -> {
        slug {
          current
        }
    },
    homePageHero {
      ...,
      ${primaryHeroFields},
      countrySpecificHeros[] {
        ...,
        countryCode,
        ${primaryHeroFields}
      }
    },
    pdpPromoBox {
      promoBoxBackgroundColor {
          hex, alpha
      },
      promoBoxForegroundColor {
          hex, alpha
      },
      promoBoxTitle,
      promoBoxBody
    },
    gwpIncludedProducts[] -> {
      title,
      slug {
         current
      },
      variants[] {
        variantId,
        cents,
        ...productVariant-> {
          cents,
          variantId,
          price,
          productId,
          productTitle,
          title,
          variantTitle
        }
      },
      _type == 'productVariant' => {
        ...,
        'product': *[_type == 'product' && references(^._id)][0],
        'variants': [{ ..., ...productVariant-> }],
      },
    },
    quantityForTriggerGiftProduct,
    giftWithPurchase[] -> {
      _type == 'productVariant' => {
        ...,
        'product': *[_type == 'product' && references(^._id)][0],
        'variant': { ..., ...productVariant-> },
      },
    },
    PLPTiles[] {
      _type,
      backgroundColor {
        hex,
      },
      textColor {
        hex,
      },
      layout,
      startIndex,
      collection[] -> { 
        title,
        slug {
          current
        }
      },
      subCollection[] -> { 
        title,
        slug {
          current
        }
      },
      autoDiscountOneTimeText,
      tabletStartIndex,
      headline,
      plpCalloutBody,
      backgroundImage ${image},
      plpCalloutFooter,
      subText,
      link[0] ${link},
      linkColor{
        hex,
      },
      showOnDesktop,
      showOnMobile,
      showOnExtraSection,
    },
    PLPBanners[] {
      _type,
      collection-> { 
        title,
        slug {
          current
        }
      },
      showThickBanner,
      thickBannerBackgroundColor {
        hex
      },
      thickBannerText, 
      thickBannerHeadline,
      thickBannerImage ${image},
      thickBannerLink[0] ${link},
      showThinBanner,
      thinBannerHeadlineColor {
        hex
      },
      thinBannerHeadline,
      thinBannerImage ${image},
    },
    shippingMeterSuccesMessage,
    cartBanner,
    cartSubscriptionToggle,
    cartSubscriptionToggleNotation,
    useOriginalPriceAsOneTimePriceSlash,
    displayDiscountPrice,
    useOriginalOneTimePriceAsSubPriceSlash,
    discountBadgeText,
    subSettings,
    collectionDiscountToActivate {
      collectionsPriorityList[] -> {
        slug {
            current
        },
        percentageDiscount,
        displayOneTimeDiscountPrice,
        percentageOneTimeDiscount,
      },
    },
    promoBar {
      ...,
      banners[] {
        ...,
        showLargerPromoBar,
        background ${backgroundColorStyle},
        linkHoverColor ${backgroundColorStyle},
        textColor ${backgroundColorStyle},
        country-> {
          countryCode,
          currencyCode,
          title,
          countryFlag ${image}
        },
        messages[] {
          ...,
          mobileContent[] ${justLinksBlock},
          desktopContent[] ${justLinksBlock},
          mobileContentLine2[] ${justLinksBlock},
          desktopContentLine2[] ${justLinksBlock},
          popupContent[] ${justLinksBlock},
          popupToggles[] {
            ...,
            content[] ${justLinksBlock}
          }
        },
      },
      autoplayDuration,
      "allCountries": ${allCountries}
    },
    cartPromo {
      isEnabled,
      ...,
      'freeShippingMeters': component[] {
        _type == "freeShippingMeter" => {
          ...,
        },
      },
      component[0] {
        _type == "freeShippingMeter" => {
          ...,
        },
        _type == "tieredPromo" => {
          countryGroups[] {
            tiers[] {
              title,
              thresholdPrice,
              messageTemplate,
              successMessage
            },
            countryCode
          }
        },
        _type == "basicMessage" => {
          ...,
          }
        },
      }
    }
  }
}`

const plps = groq`
  *[_type == 'plp'] | order(_updatedAt desc)[0] {
    collectionGroupHeader,
    collectionStickyHeader,
    'menu': collectionMenu[] {
      ...,
      collection -> {
        _id,
        title,
        '_type': 'internalLink',
        'reference': {
          _type,
          'slug': slug.current,
        },
      }
    },
  }
`

const navigation = groq`
  *[_type == 'navigation'] | order(_updatedAt desc)[0] {
    'globalNavigation': {
      gallery[]{
        'link': galleryLink[0] ${link},
        image ${image},
        badge {
          ...,
          background ${backgroundColorStyle},
        }
      },
      galleryMobile[]{
        ...,
        'link': galleryLink[0] ${link},
        image ${image},
        badge {
          ...,
          background ${backgroundColorStyle},
        }
      },
      primaryMenu[]{
        'link': menu[0] ${link},
        badge {
          ...,
          background ${backgroundColorStyle},
        }
      },
      'secondaryMenu': mission[] ${link},
      'menuButton': menuButton[0] ${link},
      additionalLinks[] ${link},
      desktopHeaderMenu[] {
        ...,
        _type == 'desktopColumnSubmenu' => {
          parentTitle,
          modules[] {
            ...,
            _type == 'SingleCategoryList' => {
              items[] {
                ...,
                'link': link[0] ${link},
                badge {
                  ...,
                  background ${backgroundColorStyle},
                },
              },
            },
            _type == 'MultiCategoryList' => {
              items[] {
                ...,
                menus[] {
                  'link': link[0] ${link},
                  badge {
                    ...,
                    background ${backgroundColorStyle},
                  },
                },
                'bottomLink': bottomLink[0] ${link},
              },
            },
            _type == 'ImageItem' => {
              ...,
              items[] {
                ...,
                link-> ${internalLinkReference},
                image ${image},
                hoverImage ${image},
              }
            },
          },
        },
        _type == 'desktopTextualSubmenu' => {
          parentTitle,
          childLinks[] ${link},
        },
        ${linkConditions},
        _type == 'coloredLink' => {
          ...,
          color ${backgroundColorStyle},
          'link': link[0] ${link},
        }
      },          
      'accountLink': accountLinc[0] ${link}
    },
    'globalNavigationOTC': {
      'gallery': galleryOTC[]{
        'link': galleryLink[0] ${link},
        image ${image},
        badge {
          ...,
          background ${backgroundColorStyle},
        }
      },
      'galleryMobile': galleryMobileOTC[]{
        ...,
        'link': galleryLink[0] ${link},
        image ${image},
        badge {
          ...,
          background ${backgroundColorStyle},
        }
      },
      'primaryMenu': primaryMenuOTC[]{
        'link': menu[0] ${link},
        badge {
          ...,
          background ${backgroundColorStyle},
        }
      },
      'secondaryMenu': missionOTC[] ${link},
      'menuButton': menuButtonOTC[0] ${link},
      'additionalLinks': additionalLinksOTC[] ${link},
      'desktopHeaderMenu': desktopHeaderMenuOTC[] {
        ...,
        _type == 'desktopColumnSubmenu' => {
          parentTitle,
          modules[] {
            ...,
            _type == 'SingleCategoryList' => {
              items[] {
                ...,
                'link': link[0] ${link},
                badge {
                  ...,
                  background ${backgroundColorStyle},
                },
              },
            },
            _type == 'MultiCategoryList' => {
              items[] {
                ...,
                menus[] {
                  'link': link[0] ${link},
                  badge {
                    ...,
                    background ${backgroundColorStyle},
                  },
                },
                'bottomLink': bottomLink[0] ${link},
              },
            },
            _type == 'ImageItem' => {
              ...,
              items[] {
                ...,
                link-> ${internalLinkReference},
                image ${image},
                hoverImage ${image},
              }
            },
          },
        },
        _type == 'desktopTextualSubmenu' => {
          parentTitle,
          childLinks[] ${link},
        },
        ${linkConditions},
        _type == 'coloredLink' => {
          ...,
          color ${backgroundColorStyle},
          'link': link[0] ${link},
        }
      },          
      'accountLink': accountLincOTC[0] ${link}
    },
    'leftMenuItems': leftNavItems[]{
      ...,
      collection -> {
        _id,
        title,
        '_type': 'internalLink',
        'reference': {
          _type,
          'slug': slug.current,
        },
      }
    },
    'rightMenuItems': rightNavItems[]{
         ...,
      collection -> {
        _id,
        title,
        '_type': 'internalLink',
        'reference': {
          _type,
          'slug': slug.current,
        },
      }
    },
    mobilePrimaryMenuV2[] {
      ...,
      'menu': menu[0] ${link},
      badge {
        ...,
        background ${backgroundColorStyle},
      }
    },
    mobileSecondaryMenuV2[] ${link},
    mobileFixedMenu[] ${link},

    mobilePrimaryMenu[] {
      ...,
      _type == 'mobileVisualSubmenu' => {
        parentTitle,
        modules[] {
          ...,
          _type == 'visualSubmenuList' => {
            items[] {
              ...,
              siloImage ${siloImage},
              'link': link[0] ${link},
              badge {
                ...,
                background ${backgroundColorStyle},
              },
            },
          },
          _type == 'visualSubmenuGrid' => {
            items[] {
              ...,
              siloImage ${siloImage},
              'link': link[0] ${link},
            },
          },
        },
      },
      _type == 'mobileTextualSubmenu' => {
        parentTitle,
        childLinks[] ${link},
      },
      ${linkConditions},
    },
    mobileSecondaryMenu[] ${link},
    desktopPrimaryMenu[] {
      ...,
      _type == 'desktopVisualSubmenu' => {
        parentTitle,
        modules[] {
          ...,
          _type == 'visualSubmenuList' => {
            items[] {
              ...,
              siloImage ${siloImage},
              'link': link[0] ${link},
              badge {
                ...,
                background ${backgroundColorStyle},
              },
            },
          },
          _type == 'visualSubmenuGrid' => {
            items[] {
              ...,
              siloImage ${siloImage},
              'link': link[0] ${link},
              badges[]-> {
                ...,
                background ${backgroundColorStyle},
              },
            },
          },
          _type == 'featuredProduct' => {
            ...,
            image ${image},
            hoverImage ${image},
            product-> {
              _type,
              title,
              productId,
              variants[] {
                ...,
                variantId,
                siloImage ${siloImage},
                ...productVariant-> {
                  ...,
                }
              },
              thumbnailImage ${image},
              'slug': slug.current,
            },
          },
        },
      },
      _type == 'desktopTextualSubmenu' => {
        parentTitle,
        childLinks[] ${link},
      },
      ${linkConditions},
    },
    desktopSecondaryMenu[] ${link},
    desktopPrimaryMenuV2[] {
      ...,
      _type == 'desktopColumnSubmenu' => {
        parentTitle,
        modules[] {
          ...,
          _type == 'SingleCategoryList' => {
            items[] {
              ...,
              'link': link[0] ${link},
              badge {
                ...,
                background ${backgroundColorStyle},
              },
            },
          },
          _type == 'MultiCategoryList' => {
            items[] {
              ...,
              menus[] {
                'link': link[0] ${link},
                badge {
                  ...,
                  background ${backgroundColorStyle},
                },
              },
              'bottomLink': bottomLink[0] ${link},
            },
          },
          _type == 'ImageItem' => {
            ...,
            items[] {
              ...,
              link-> ${internalLinkReference},
              image ${image},
              hoverImage ${image},
            }
          },
        },
      },
      _type == 'desktopTextualSubmenu' => {
        parentTitle,
        childLinks[] ${link},
      },
      ${linkConditions},
      _type == 'coloredLink' => {
        ...,
        color ${backgroundColorStyle},
        'link': link[0] ${link},
      }
    },
    desktopSecondaryMenuV2[] ${link},
    bgColorConfig[]{
      ...,
      link ${internalLink},
      color ${backgroundColorStyle},
    }
  }
`

const globals = groq`
  *[_type == 'globals'] | order(_updatedAt desc)[0] {
    ...,
    'homepageCta': homepageCta[0] ${link},
    enableMobileHomepageCta,
    openGraphImage ${image},
    twitterImage ${image},
    allowIndexing,
    showSecondaryImage,
    customerSegmentIds,
    tooltipSettings {
      tooltipText,
      includedCollections[] -> {
        slug {
          current
        }
      },
      excludedCollections[] -> {
          slug {
              current
          }
      },
      includedProducts[] -> {
          slug {
            current
          }
      },     
      excludedProducts[] -> {
        slug {
          current
        }
      },
    },
    globalAutoDiscount {
      ...,
      giftProduct-> {
        title,
        cents,
        productId,
        variants[] {
          ...,
          siloImage ${siloImage},
          ...productVariant-> {
            ...,
          }
        },
        options,
      },
    },
    modals {
      ...,
      subscriptionRefillModal {
        ...,
        headImage ${image}
      }
    }
  }
`

const footer = groq`
  *[_type == 'footer'] | order(_updatedAt desc)[0] {
    ...,
    menus[] {
      ...,
      links[] {
        ...${link},
        image ${image}
      },
    },
    socialMedia[] {
      ...,
      links[] {
        ...${link},
        image ${image}
      },
    },
    secondaryMenu[] ${link},
    cert {
      ...,
      certificates [] ${image}
    }
  }
`

const cart = groq`
  *[_type == 'cart'] | order(_updatedAt desc)[0] {
    ...,
    empty {
      ...,
      'cta': cta[0] ${link},
    },
    donation {
      ...,
      product-> {
        title,
        productId,
        cents,
        shopifyImage ${image},
        options,
        variants[] {
          ...,
          variantId,
          siloImage ${siloImage},
          ...productVariant-> {
            ...,
          }
        },
      },
      iconImage ${image},
    },
    promo {
      isEnabled,
      ...,
      'freeShippingMeters': component[] {
        _type == "freeShippingMeter" => {
          ...,
        },
      },
      component[0] {
        _type == "freeShippingMeter" => {
          ...,
        },
        _type == "tieredPromo" => {
          countryGroups[] {
            tiers[] {
              title,
              thresholdPrice,
              messageTemplate,
              successMessage
            },
            countryCode
          }
        },
        _type == "basicMessage" => {
          ...,
        }
      },
    },
    upsells {
      ...,
      itemBgColor ${backgroundColorStyle},
      products[]-> {
        _type,
        _type == 'product' => {
          'product': {
            ...,
            defaultVariant->{
              variantId
            },
            autoDiscount ${plpAutoDiscount},
            shopifyImage ${image},
            'collections': *[_type == 'collection' && references(^._id)] {
              _id,
              title,
              'slug': slug.current,
            }
          },
          variants[] {
            ...,
            siloImage ${siloImage},
            ...productVariant-> {
              ...,
            }
          },
        },
        _type == 'productVariant' => {
          'product': *[_type == 'product' && references(^._id)][0],
          'variants': [{
            ..., 
            siloImage ${siloImage}, 
            ...productVariant-> 
          }],
        },
      },
    },
    crossell {
      ...,
      products[@->isEnabled && (@->soldOutSettings['isSoldOut'] == false || @->soldOutSettings == null)][]-> {
        _type,
        _type == 'product' => {
          'product': {
            ...,
            isYotpoDisabled,
            isPlpStarRatingHidden,
            priceNotation ${priceNotation},
            badges[]-> {
              ...,
              background ${backgroundColorStyle},
            },
            thumbnailImage ${image},
            shopifyImage ${image},
            'slug': slug.current,
            variants[] {
              ...,
              cents,
              ...productVariant-> {
                ...,
              }
            },
            options,
            'link': {
              '_type': 'internalLink',
              'reference': {
                '_type': _type,
                'slug': slug.current,
              },
            },
          },
          'variant': variants[0] {
            ...,
            siloImage ${siloImage},
            ...productVariant->
          },
        },
        _type == 'productVariant' => {
          'product': *[_type == 'product' && references(^._id)][0],
          'variant': { ..., ...productVariant-> },
        },
      },
    },
    bestSellers {
      ...,
      products[@->isEnabled == true && (@->soldOutSettings['isSoldOut'] == false || @->soldOutSettings == null)][]-> {
        _type,
        _type == 'product' => {
          'product': {
            ...,
            isYotpoDisabled,
            isPlpStarRatingHidden,
            priceNotation ${priceNotation},
            badges[]-> {
              ...,
              background ${backgroundColorStyle},
            },
            thumbnailImage ${image},
            shopifyImage ${image},
            'slug': slug.current,
            variants[] {
              ...,
              cents,
              ...productVariant-> {
                ...,
              }
            },
            options,
            'link': {
              '_type': 'internalLink',
              'reference': {
                '_type': _type,
                'slug': slug.current,
              },
            },
          },
          'variant': variants[0] {
            ...,
            siloImage ${siloImage},
            ...productVariant-> {
              ...,
            }
          },
        },
        _type == 'productVariant' => {
          'product': *[_type == 'product' && references(^._id)][0],
          'variant': { 
            ..., 
            ...productVariant-> {
              ...,
            }
          },
        },
      },
    },
    cartPageMarquee {
      ...,
      background ${backgroundColorStyle},
      separatorIcon ${image},
    }
  }
`

const kitSubscriptionProductFields = groq`
  _id,
  title,
  _type,
  'slug': slug.current,
  cents,
  price,
  options,
  isEnabled,
  showReviewsWidget,
  showPDPBottomLine,
  showPLPReviews,
  showReviewsInCart,
  productId,
  thumbnailImage ${image},
  shopifyImage ${image},
  variants[] {
    ...,
    siloImage ${siloImage},
    ...productVariant-> {
      ...,
    }
  },
  sellingPlans[]
`

const advancedCta = groq`{
  'background': background[0] {
    _type == 'backgroundColorStyle' => ${backgroundColorStyle},
    _type == 'imageAsset' || _type == 'videoAsset' => ${media},
  },
  'desktopBackground': background[1] {
    _type == 'backgroundColorStyle' => ${backgroundColorStyle},
    _type == 'imageAsset' || _type == 'videoAsset' => ${media},
  },
  mobileBgStyle,
  contentWidth,
  contentAlignment,
  bodyRichText,
  footer,
  headline,
  headlineColor ${backgroundColorStyle},
  body,
  bodyColor ${backgroundColorStyle},
  textMark {
    ...,
    textColor ${backgroundColorStyle}
  },
  cta {
    ...,
    'link': link[0] ${link},
  },
}`

const richText = groq`{
  ...,
  _type == 'image' => ${image},
  _type == 'imageCollage' || _type == 'twoUpImages' => {
    images[] ${image},
  },
  markDefs[] {
    ...,
    ${linkConditions},
  },
}`

const globalModules = groq`{
  ...,
  _type == 'ingredientModule' => {
    ...,
    tabs[] {
      ...,
      allowListicleIcons,
      bodyText,
      listicleCols,
      tabImage ${image},
      tabHeadline,
      desktopLayout,
      mobileLayout,
      listicleItems[] {
         ...,
        listicleIcon ${image},
        text
      },
      additionalIcons[] {
        ...,
      additionalIcon {
        ...,
        asset -> {
          ...,
          }
        }
      }
    }
  },
  _type == 'primaryHero' => {
    ...,
    ${primaryHeroFields},
    countrySpecificHeros[] {
      ...,
      countryCode,
      ${primaryHeroFields}
    }
  },
  _type == 'primaryHeroV2' => {
    'headImageMedia': headImage[0] {
      image ${image}
    },
    'headImageMediaDesktop': headImage[1] {
      image ${image}
    },
    headlineColor ${backgroundColorStyle},
    bodyColor ${backgroundColorStyle},
    cta {
      ...,
      'link': link[0] ${link},
    },
    'bottomImageMedia': bottomImage[0] {
      image ${image}
    },
    'bottomImageMediaDesktop': bottomImage[1] {
      image ${image}
    },
    'layout': layout[0] {
      _type,
      desktopOrder,
      mobileOrder,
      isWaveEnabled,
      backgroundStyle,
      'background': backgroundColorStyle ${backgroundColorStyle},
      'backgroundMedia': backgroundMedia[0] ${media},
      gradientStyle,
      align
    },
    ${responsiveMedia},
  },
  _type == 'moment' => {
    siloImages[] ${siloImage},
    bgColor ${backgroundColorStyle},
    bodyColor ${backgroundColorStyle}
  },
  _type == 'stickyScrollCarousel' => {
    items[] {
      _key,
      'media': media[0] ${media},
      content[] {
        ...,
        _type == 'iconImage' => ${image},
        markDefs[] {
          ...,
          ${linkConditions},
        },
      },
    },
  },
  _type == 'iconGrid' => {
    background ${backgroundColorStyle},
    icons[] {
      _key,
      image ${image},
      caption,
    }
  },
  _type == 'iconModule' => {
    heading,
    subheading,
    background ${backgroundColorStyle},
    items[] {
      _key,
      image ${image},
      title,
      description,
      isTextFirst
    },
    mobileGridStyle,
    bottomLine
  },
  _type == 'categoryModule' => {
    heading,
    subTitle,
    background ${backgroundColorStyle},
    items[] {
      _key,
      image ${image},
      hoverImage ${image},
      background ${backgroundColorStyle},
      title,
      description,
      'link': link[0] ${link}
    },
  },
  _type == 'featuredCollections' => {
    'items': links[] {
      _key,
      title,
      subtitle,
      ctaText,
      'link': collection-> {
        '_type': 'internalLink',
        'reference': {
          '_type': 'collection',
          'slug': slug.current,
        },
      },
      ellipseFill ${backgroundColorStyle},
      siloImage ${siloImage},
    },
  },
  _type == 'quickAddSubscriptionKit' && product->isEnabled == true  => {
    'media': media[] ${media},
    product-> {
      'slug': slug.current,
      title,
      cents,
      productId,
      isYotpoDisabled,
      defaultPlanSelection,
      shopifyImage ${image},
      options,
      soldOutSettings,
      unbundledTotal,
      badges[]-> {
        ...,
        background ${backgroundColorStyle},
      },
      variants[] {
        ...,
        siloImage ${siloImage},
        ...productVariant-> {
          ...,
          whatsIncluded {
            items[]-> {
              ...,
              siloImage ${siloImage},
              content[] ${justLinksBlock},
            }
          }
        }
      },
      'collections': *[_type == 'collection' && references(^._id)] {
        _id,
        title,
        'slug': slug.current,
        description,
        displayDiscountPrice,
        percentageDiscount,
        discountBadgeText,
        discountThemeColor,
        hideStrikeoutPrice,
        hideRecurringPrice,
        cartSubscriptionTitle,
        subscriptionOptionTitle,
        oneTimeOptionTitle
      },
      attachProducts {
        ...,
        products[@['product']->isEnabled == true && (@['product']->soldOutSettings['isSoldOut'] == false || @['product']->soldOutSettings == null)] [] {
          ...,
          image ${image},
          product -> {
            'slug': slug.current
          }
        }
      },
      categoryTrail[] {
        ...,
        link {
          reference-> {
            _type,
            'slug': slug.current,
            title
          },
          'title': reference->title,
          '_type': 'internalLink',
        }
      },
      certs[] {
        ...,
        text,
        logo ${image}
      },
      variants[] {
        ...,
        mediaGallery[] ${media},
        siloImage ${siloImage},
        soldOutSettings,
        ...productVariant-> {
          ...,
          whatsIncluded {
            items[]-> {
              ...,
              siloImage ${siloImage},
              content[] ${justLinksBlock},
            }
          }
        }
      },
      sellingPlans[],
      badges[]-> {
        ...,
        background ${backgroundColorStyle},
      },
      formulas[]-> {
        ...,
        ingredients[] {
          ...,
          functions[] ${justLinksBlock},
        },
      },
      showReviewsWidget,
      showPDPBottomLine,
      showPLPReviews,
      showReviewsInCart,
      mediaGallery[] ${media},
      drawers {
        position,
        showAsAccordion,
        items[] {
          ...,
          _type == 'overview' => {
            content[] ${justLinksBlock},
          },
          _type == 'whatsIncluded' => {
            items[]-> {
              ...,
              siloImage ${siloImage},
              content[] ${justLinksBlock},
            }
          },
          _type == 'ingredients' => {
            content[] ${justLinksBlock},
          },
          _type == 'sustainability' => {
            content[] ${justLinksBlock},
          },
        },
      },
      optionSettings,
      optionValueSettings[] {
        ...,
        _type == 'icon' => {
          image ${siloImage},
        },
      },
      refillProduct-> {
       ${kitSubscriptionProductFields},
      },
      subscription->,
      options,
      'plpSwatch': plpSwatch {
        ...,
        swatchItems[] {
          ...,
          swatchIcon ${image},
          colorStack ${backgroundColorStyle},
        },
        swatchItems2[] {
          _type,
          _key,
          option,
          swatchIcon {
            asset->,
          },
          colorStack {
              ...reference-> {
              ...style[0]
            },
          },
          color {
            hex,
            rgb
          }
        }
      },
      'plpSwatch2': plpSwatch2 {
        ...,
        swatchItems[] {
          ...,
          swatchIcon ${image},
          colorStack {
            ...,
            ...reference-> {
              ...,
              ...style[0],
            },
          },
        }
      },
      compareOverlay,
      thumbnailImage ${image},
      shopifyImage ${image},
      upsell {
        ...,
        product-> {
          ...,
          'slug': slug.current,
          'siloImage': variants[0].productVariant->.siloImage ${siloImage},
        }
      },
      openGraphImage ${image},
      twitterImage ${image},
      allowIndexing,
      cartUpsellProducts,
      contentModules,
      defaultVariant->{
        variantId,
        variantTitle,
      },
      isAfterpayDisabled,
      isEnabled,
      isQuantitySelectorEnabled,
      isSubFirstOption,
      metaDescription,
      metaTitle,
      overrideMainHeader,
      price,
      productTitle,
      showUnitPrice,
      subscriptionType,
      tags,
      titleAndBottomlinePosition,
      unitPriceLabel,
      unitPriceLabelPlural,
    },
    enableStickyATC,
    productDescription,
    productDisplayOption,
    hideSubFrequencySelector,
    hideBreadcrumbs,
    productGalleryHeader,
    productGalleryHeaderBackgroundColor ${backgroundColorStyle},
    enableAltAppearance,
    showProductDescriptionAfterVariants,
    bottomLineMessage,
    subscriptionOptionTitle,
    subscriptionOptionSubtitle,
    subscriptionOptionCallout,
    oneTimeOptionTitle,
    cartSubscriptionTitle,
    buyOneTimeCallout,
    recurringPricingTitle,
    spacingTopMobile,
    spacingTopDesktop,
    spacingTopTablet,
    spacingBottomTablet,
    spacingBottomMobile,
    spacingBottomDesktop,
    percentageDiscount,
    strikeOutPriceOverride,
    hideSubFrequencySelector,
    whatsIncludedLabel,
    whatsIncludedList,
    listColumn,
    subscriptionCaption,
    subscriptionCaptionV2,
    bgColor ${backgroundColorStyle},
    bodyColor ${backgroundColorStyle}
  },
  _type == 'productHero' && product->isEnabled == true  => {
    'media': media[] ${media},
    product-> {
      'slug': slug.current,
      title,
      cents,
      soldOutSettings,
      productId,
      isYotpoDisabled,
      defaultPlanSelection,
      shopifyImage ${image},
      options,
      badges[]-> {
        ...,
        background ${backgroundColorStyle},
      },
      variants[] {
        ...,
        siloImage ${siloImage},
        ...productVariant-> {
          ...,
          whatsIncluded {
            items[]-> {
              ...,
              siloImage ${siloImage},
              content[] ${justLinksBlock},
            }
          }
        }
      },
      'collections': *[_type == 'collection' && references(^._id)] {
        _id,
        title,
        'slug': slug.current,
        description,
        displayDiscountPrice,
        percentageDiscount,
        discountBadgeText,
        discountThemeColor,
        hideStrikeoutPrice,
        hideRecurringPrice,
        subscriptionOptionTitle,
        oneTimeOptionTitle,
        cartSubscriptionTitle
      },
      attachProducts {
        ...,
        products[@['product']->isEnabled == true && (@['product']->soldOutSettings['isSoldOut'] == false || @['product']->soldOutSettings == null)] [] {
          ...,
          image ${image},
          product -> {
            'slug': slug.current
          }
        }
      },
      categoryTrail[] {
        ...,
        link {
          reference-> {
            _type,
            'slug': slug.current,
            title
          },
          'title': reference->title,
          '_type': 'internalLink',
        }
      },
      certs[] {
        ...,
        text,
        logo ${image}
      },
      variants[] {
        ...,
        mediaGallery[] ${media},
        siloImage ${siloImage},
        soldOutSettings,
        ...productVariant-> {
          ...,
          whatsIncluded {
            items[]-> {
              ...,
              siloImage ${siloImage},
              content[] ${justLinksBlock},
            }
          }
        }
      },
      badges[]-> {
        ...,
        background ${backgroundColorStyle},
      },
      formulas[]-> {
        ...,
        ingredients[] {
          ...,
          functions[] ${justLinksBlock},
        },
      },
      showReviewsWidget,
      showPDPBottomLine,
      showPLPReviews,
      showReviewsInCart,
      mediaGallery[] ${media},
      drawers {
        position,
        items[] {
          ...,
          _type == 'overview' => {
            content[] ${justLinksBlock},
          },
          _type == 'whatsIncluded' => {
            items[]-> {
              ...,
              siloImage ${siloImage},
              content[] ${justLinksBlock},
            }
          },
          _type == 'ingredients' => {
            content[] ${justLinksBlock},
          },
          _type == 'sustainability' => {
            content[] ${justLinksBlock},
          },
        },
      },
      optionSettings,
      optionValueSettings[] {
        ...,
        _type == 'icon' => {
          image ${siloImage},
        },
      },
      subscription->,
      options,
      'plpSwatch': plpSwatch {
        ...,
        swatchItems[] {
          ...,
          swatchIcon ${image},
          colorStack ${backgroundColorStyle},
        },
        swatchItems2[] {
          _type,
          _key,
          option,
          swatchIcon {
            asset->,
          },
          colorStack {
              ...reference-> {
              ...style[0]
            },
          },
          color {
            hex,
            rgb
          }
        }
      },
      'plpSwatch2': plpSwatch2 {
        ...,
        swatchItems[] {
          ...,
          swatchIcon ${image},
          colorStack {
            ...,
            ...reference-> {
              ...,
              ...style[0],
            },
          },
        }
      },
      compareOverlay,
      thumbnailImage ${image},
      shopifyImage ${image},
      upsell {
        ...,
        product-> {
          ...,
          'slug': slug.current,
          'siloImage': variants[0].productVariant->.siloImage ${siloImage},
        }
      },
      openGraphImage ${image},
      twitterImage ${image},
      allowIndexing,
      cartUpsellProducts,
      contentModules,
      defaultVariant->{
        variantId,
        variantTitle,
      },
      isAfterpayDisabled,
      isEnabled,
      isQuantitySelectorEnabled,
      isSubFirstOption,
      metaDescription,
      metaTitle,
      overrideMainHeader,
      price,
      productTitle,
      showUnitPrice,
      subscriptionType,
      tags,
      titleAndBottomlinePosition,
      unitPriceLabel,
      unitPriceLabelPlural,
    },
    enableStickyATC,
    productDescription,
    whatsIncludedLabel,
    whatsIncludedList,
    listColumn,
    subscriptionCaption,
    subscriptionCaptionV2,
    bgColor ${backgroundColorStyle},
    bodyColor ${backgroundColorStyle}
  },
  _type == 'curatedReviewsCarousel' => {
    reviews[] {
      ...,
      'productTitle': product->.title,
      'link': {
        '_type': 'internalLink',
        'reference': product-> {
          _type,
          'slug': slug.current,
        },
      },
    },
  },
  _type == 'twoUp' => {
    headline,
    body,
    desktopOrder,
    mobileOrder,
    disclaimerText,
    miniImage,
    isMediaHiddenOnMobile,
    fullWidthImage,
    bgColor ${backgroundColorStyle},
    'media': media[0] ${media},
    'link': cta[0] ${link},
    spacingTopMobile,
    spacingTopDesktop,
    spacingTopTablet,
    spacingBottomTablet,
    spacingBottomMobile,
    spacingBottomDesktop,
    button {
      buttonType,
      _type,
      'link': link[0] ${link},
    },
    descriptionListItems[] {
      image ${image},
      heading,
      description,
      descriptionWithFormatting,
      accordionItem
    },
    icons[] {
      _key,
      image ${image},
      caption,
      iconOriginalSize,
    },
  },
  _type == 'CustomerLogin' => {
    formTitle,
    formSubtitle
  },
  _type == 'CustomerRecoverPassword' => {
    formTitle,
    formSubtitle,
  },
  _type == 'certificationModule' => {
    ...,
    backgroundColor ${backgroundColorStyle},
    icons[] {
      _key,
      image ${image},
      caption,
    },
  },
  _type == 'press' => {
    overline,
    backgroundColor,
    logos[] {
      _key,
      svg ${image},
      title,
      link ${link},
      isTextFirst
    },
  },
  _type == 'marquee' => {
    background ${backgroundColorStyle},
    separatorIcon ${image},
  },
  _type == 'newModeEmbeddedForm' => {
    scriptSource,
    containerId
  },
  _type == 'ugcVideo' => {
    title,
    titleColorRef ${backgroundColorStyle},
    subtitle,
    aspectRatio,
    subtitleColorRef ${backgroundColorStyle},
    bgColor ${backgroundColorStyle},
    videoLinks[] {
      _key,
      title,
      video {
        videoPopupBackgroundColor ${backgroundColorStyle},
        videoUrlForCarousel ${video},
        videoUrl ${video},
        videoPopupBackground ${image}
      }
    }
  },
  _type == 'imageCarousel' => {
    headline,
    'link': cta[0] ${link},
    items[] {
      _key,
      image ${image},
      'link': link[0] ${link},
    },
  },
  _type == 'secondaryHero' => {
    'background': background[0] {
      _type == 'backgroundColorStyle' => ${backgroundColorStyle},
      _type == 'imageAsset' || _type == 'videoAsset' => ${media},
    },
    'desktopBackground': background[1] {
      _type == 'backgroundColorStyle' => ${backgroundColorStyle},
      _type == 'imageAsset' || _type == 'videoAsset' => ${media},
    },
    contentAlignment,
    headline,
    body,
    isWaveEnabled,
    overline,
  },
  _type == 'callout' => {
    overline,
    text,
    body,
    'link': cta[0] ${link},
    background ${backgroundColorStyle},
    siloImages[] ${siloImage},
    bgImage[] ${image},
    contentAlignment,
    fullWidthText,
  },
  _type == 'cardCarousel' => {
    overline,
    cards[] {
      _key,
      title,
      body,
      image ${image},
      'link': cta[0] ${link},
    },
  },
  _type == 'whatsInYourKit' => {
    headline,
    background ${backgroundColorStyle},
    maxColumnCount,
    desktopCarousel,
    items[]-> {
      _id,
      image ${image},
      title,
      subtitle,
    },
  },
  _type == 'savingsCalculator' => {
    overline,
    headline,
    body,
    background ${backgroundColorStyle},
    ourIcon ${image},
    theirIcon ${image},
    options[] {
      _key,
      count,
      label,
      ourPrice,
      theirPrice,
    },
  },
  _type == 'columns' => {
    headline,
    body,
    showSideBySide,
    background ${backgroundColorStyle},
    columns[] {
      _key,
      'media': media[0] ${media},
      content[] {
        ...,
        _type == 'iconList' => {
          items,
          'icon': icon[0] {
            ...,
            _type == 'icon' => {
              image ${image},
            },
          }
        },
      },
    },
  },
  _type == 'openLetter' => {
    background ${backgroundColorStyle},
    image ${image},
    content[] {
      ...,
      _type == 'signOff' => {
        text,
        signatureImage ${image},
      }
    },
  },
  _type == 'statsScroller' => {
    'media': media[0] ${media},
    stats[] {
      _key,
      caption,
      number,
    },
  },
  _type == 'threeUpScroller' => {
    background ${backgroundColorStyle},
    overline,
    headline,
    columns[] {
      _key,
      title,
      body,
    },
  },
  _type == 'productMarquee' => {
    background ${backgroundColorStyle},
    overline,
    headline,
    body,
    'link': cta[0] ${link},
    marqueeImage ${image},
    marqueePosition,
  },
  _type == 'basicCta' => {
    background ${backgroundColorStyle},
    headline,
    cta {
      ...,
      'link': link[0] ${link},
    },
  },
  _type == 'advancedCta' => ${advancedCta},
  _type == 'howItWorksScroller' => {
    background ${backgroundColorStyle},
    headline,
    body,
    fig1 {
      siloImage ${siloImage},
      title,
      subtitle,
    },
    fig2 {
      siloImage ${siloImage},
      title,
      subtitle,
    },
    fig3 {
      media[0] ${media},
      title,
      subtitle,
    },
    'link': cta[0] ${link},
  },
  _type == 'howWeStackUp' => {
    headline,
    criteriaTitles[] {
      title,
      brandCols[] {
        brandRef -> {
          name,
          brandLogo {
            asset -> {
              url,              
              _id,
              metadata {
                dimensions {
                  aspectRatio,
                  lqip,
                }
              }
            }
          }
        },
        hideIcon,
        hideText,
        text,
        isChecked,
      }
    },
    additionalDescriptions[],
  },
  _type == 'beforeAfterCarousel' => {
    content[] {
      ratings,
      review ,
      beforeImage ${image},
      afterImage ${image},
    },
    background ${backgroundColorStyle},
  },
  _type == 'productCarousel' => {
    headline,
    products[@->isEnabled == true && (@->soldOutSettings['isSoldOut'] == false || @->soldOutSettings == null)][]-> {
      _type,
      _id,
      title,
      price,
      altImageForABTest,
      'slug': slug.current,
      thumbnailImage ${image},
      shopifyImage ${image},
      'link': {
        '_type': 'internalLink',
        'reference': {
          '_type': _type,
          'slug': slug.current,
        },
      },
      subscription-> {
        ...,
      },
      sellingPlans[],
      subscriptionType,
      variants[] {
        ...,
        cents,
        ...productVariant->
      },
      'plpSwatch': plpSwatch {
        ...,
        swatchItems[] {
          ...,
          swatchIcon ${image},
          colorStack ${backgroundColorStyle},
        },
        swatchItems2[] {
          _type,
          _key,
          option,
          swatchIcon {
            asset->,
          },
          colorStack {
              ...reference-> {
              ...style[0]
            },
          },
          color {
            hex,
            rgb
          }
        }
      },
      priceNotation ${priceNotation},
      options,
      'collections': *[_type == 'collection' && references(^._id)] {
        _id,
        title,
        'slug': slug.current,
        description,
        hideStrikeoutPrice,
        hideRecurringPrice,
        cartSubscriptionTitle
      },
      _type == 'product' => {
        productId,
        cents,
        shopifyImage ${image},
        variants[] {
          ...,
          cents,
          ...productVariant->
        },
      },
    },
    background ${backgroundColorStyle},
    scrollable,

  },
  _type == 'advProductCarousel' => {
    ...,
    products[@->isEnabled == true && (@->soldOutSettings['isSoldOut'] == false || @->soldOutSettings == null)][]-> {
      _type,
      _id,
      productId,
      isEnabled,
      altImageForABTest,
      title,
      spacingTopMobile,
      spacingTopDesktop,
      spacingTopTablet,
      spacingBottomTablet,
      spacingBottomMobile,
      spacingBottomDesktop,
      'slug': slug.current,
      defaultVariant->{
        variantId,
        variantTitle
      },
      isYotpoDisabled,
      subscription-> {
        ...,
      },
      sellingPlans[],
      subscriptionType,
      isPlpStarRatingHidden,
      thumbnailImage ${image},
      priceNotation ${priceNotation},
      badges[]-> {
        ...,
        background ${backgroundColorStyle},
      },
      options,
      compareOverlay,
      'plpSwatch': plpSwatch {
        ...,
        swatchItems[] {
          ...,
          swatchIcon ${image},
          colorStack ${backgroundColorStyle},
        },
        swatchItems2[] {
          _type,
          _key,
          option,
          swatchIcon {
            asset->,
          },
          colorStack {
              ...reference-> {
              ...style[0]
            },
          },
          color {
            hex,
            rgb
          }
        }
      },
      'collections': *[_type == 'collection' && references(^._id)] {
        _id,
        title,
        'slug': slug.current,
        description,
        hideStrikeoutPrice,
        hideRecurringPrice,
        cartSubscriptionTitle
      },
      _type == 'product' => {
        productId,
        cents,
        shopifyImage ${image},
        variants[] {
          ...,
          cents,
          ...productVariant-> {
            ...,
          }
        },
      },
      _type == 'productLander' => {
        'productSlug': product->.slug.current,
        'productId': product->.productId,
        'cents': product->.cents,
        'shopifyImage': product->.shopifyImage ${image},
        ...product-> {
          variants[] {
            ...,
            cents,
            ...productVariant-> {
              ...,
            }
          },
        },
      },
    },
    background ${backgroundColorStyle},
    scrollable,
    useScrollGrid
  },
  _type == "subscriptionPLP" => {
    ...,
    filters[] {
      name,
      slug
    },
    button {
      buttonType,
      _type,
      'link': link[0] ${link},
    },
    products[@->isEnabled == true && (@->soldOutSettings['isSoldOut'] == false || @->soldOutSettings == null)][]-> {
      _type,
      _id,
      title,
      price,
      subscriptionPlpDescription,
      altImageForABTest,
      'slug': slug.current,
      thumbnailImage ${image},
      shopifyImage ${image},
      'link': {
        '_type': 'internalLink',
        'reference': {
          '_type': _type,
          'slug': slug.current,
        },
      },
      subscription->,
      sellingPlans[],
      subscriptionType,
      priceNotation ${priceNotation},
      options,
      'collections': *[_type == 'collection' && references(^._id)] {
        _id,
        title,
        'slug': slug.current,
      },
      _type == 'product' => {
        productId,
        cents,
        shopifyImage ${image},
      },
    },
    background ${backgroundColorStyle},
  },
  _type == 'richText' => {
    content[] ${richText},
  },
  _type == 'anchorPlacement' => {
    ...,
  },
  _type == 'scrollToSection' => {
    anchor-> {
      _id,
      title,
      '_type': 'internalLink',
      'reference': ${internalLinkReference},
    },
    title,
    sectionHash
  },
  _type == 'ugcModule' => {
    ...,
    items[] {
      ...,
      image ${image},
    }
  },
  _type == 'scrollToHash' => {
    hash
  },
  _type == 'lpHero' => {
    ...,
    items[] {
      ...,
      image ${image},
    },
    cta {
      ...,
      scrollTo -> {
        _id,
        hash
      }
    },
    product -> {
      productId,
      shopifyImage ${image},
      badges[]-> {
        ...,
        background ${backgroundColorStyle},
      },
      isYotpoDisabled
    }
  },
  _type == 'comparisonModule' => {
    ...,
    background ${backgroundColorStyle},
    products[] {
      ...,
      product -> {
        title,
        productId,
        productTitle,
        price,
        priceNotation ${priceNotation},
        'image': shopifyImage ${image},
        variants[] {
          ...,
          ...productVariant-> {
            ...,
          }
        },
        badges[]-> {
          ...,
          background ${backgroundColorStyle},
        },
        options,
      },
      priceNotation ${priceNotation},
      giftProduct -> {
        productId,
        price,
        variants[] {
          ...,
          ...productVariant-> {
            ...,
          }
        },
        options,
      }
    }
  },
  _type == 'videoHero' => {
    ...,
    poster ${image}
  },
  _type == 'reviewModuleV2' => {
    ...,
    headerImage ${image},
    background ${backgroundColorStyle},
    reviews[] {
      ...,
      image ${image},
    }
  }
}`

const pdpProjection = groq`
  ...,
  'slug': slug.current,
  'collections': *[_type == 'collection' && references(^._id)] {
    _id,
    title,
    'slug': slug.current,
    description,
    displayDiscountPrice,
    percentageDiscount,
    useOriginalOneTimePriceAsSubPriceSlash,
    displayOneTimeDiscountPrice,
    percentageOneTimeDiscount,
    useOriginalPriceAsOneTimePriceSlash,
    discountBadgeText,
    discountThemeColor,
    subscriptionOptionTitle,
    oneTimeOptionTitle,
    cartSubscriptionTitle,
    promoBoxBackgroundColor,
    promoBoxTitle,
    promoBoxBody,
    hideRecurringPrice,
    hideStrikeoutPrice
  },
  attachProducts {
    ...,
    products[@['product']->isEnabled == true && (@['product']->soldOutSettings['isSoldOut'] == false || @['product']->soldOutSettings == null)] [] {
      ...,
      image ${image},
      product -> {
        'slug': slug.current
      }
    }
  },
  "extraProductVariant": extraProductVariant->{
    ...,
    plpSwatch {
      ...,
      swatchItems[] {
        ...,
        colorStack-> {
          ...reference-> {
            ...style[0],
          },
        }
      },
      swatchItems2[] {
        _type,
        _key,
        option,
        swatchIcon {
          asset->,
        },
        colorStack {
            ...reference-> {
            ...style[0]
          },
        },
        color {
          hex,
          rgb
        }
      }
    },
    variants[] {
      productVariant->{
        ...
      }
    }
  },
  categoryTrail[] {
    ...,
    link {
      reference-> {
        _type,
        'slug': slug.current,
        title
      },
      'title': reference->title,
      '_type': 'internalLink',
    }
  },   
  certs[] {
    ...,
    text,
    logo ${image}
  },
  'plpSwatch': plpSwatch {
    ...,
    swatchItems[] {
      ...,
      swatchIcon ${image},
      colorStack ${backgroundColorStyle},
    },
    swatchItems2[] {
      _type,
      _key,
      option,
      swatchIcon {
        asset->,
      },
      colorStack {
          ...reference-> {
          ...style[0]
        },
      },
      color {
        hex,
        rgb
      }
    }
  },
  'plpSwatch2': plpSwatch2 {
    ...,
    swatchItems[] {
      ...,
      swatchIcon ${image},
      colorStack {
        ...,
        ...reference-> {
          ...,
          ...style[0],
        },
      },
    }
  },
  variants[] {
    ...,
    mediaGallery[] ${media},
    siloImage ${siloImage},
    freeExtraProduct[] -> {
      _type == 'productVariant' => {
        productId,
        variantId
      },
    },
    ...productVariant-> {
      ...,
      whatsIncluded {
        items[]-> {
          ...,
          siloImage ${siloImage},
          content[] ${justLinksBlock},
        }
      }
    }
  },
  badges[]-> {
    ...,
    background ${backgroundColorStyle},
  },
  formulas[]-> {
    ...,
    ingredients[] {
      ...,
      functions[] ${justLinksBlock},
    },
  },
  showReviewsWidget,
  showPDPBottomLine,
  showPLPReviews,
  showReviewsInCart,
  mediaGallery[] ${media},
  drawers {
    position,
    showAsAccordion,
    items[] {
      ...,
      _type == 'overview' => {
        content[] ${justLinksBlock},
      },
      _type == 'whatsIncluded' => {
        items[]-> {
          ...,
          siloImage ${siloImage},
          content[] ${justLinksBlock},
        }
      },
      _type == 'ingredients' => {
        content[] ${justLinksBlock},
      },
      _type == 'sustainability' => {
        content[] ${justLinksBlock},
      },
    },
  },
  optionSettings,
  optionValueSettings[] {
    ...,
    _type == 'icon' => {
      image ${siloImage},
    },
  },
  subscription->,
  defaultVariant->{
    variantId,
    variantTitle,
    cartSubtitle,
    cartOneTimeSubtitle
  },
  customPriceSlash,
  thumbnailImage ${image},
  shopifyImage ${image},
  upsell {
    ...,
    product-> {
      ...,
      'slug': slug.current,
      'siloImage': variants[0].productVariant->.siloImage ${siloImage},
    }
  },
  'modules': contentModules[] ${globalModules},
  openGraphImage ${image},
  allowIndexing,
  twitterImage ${image},
  soldOutSettings
`

const productLander = groq`{
  ${pdpProjection},
  'options': product->.options,
  ...product-> {
    subscription->,
    variants[]{
      ...,
      mediaGallery[] ${media},
      siloImage ${siloImage},
      soldOutSettings,
      ...productVariant-> {
        ...,
      }
    },
  },
  'productSlug': product->.slug.current,
  'productId': product->.productId,
  'cents': product->.cents,
  'price': product->.price,
  'shopifyImage': product->.shopifyImage ${image},
  'optionSettings': product->.optionSettings,
  'optionValueSettings': product->.optionValueSettings[] {
    ...,
    _type == 'icon' => {
      image ${siloImage},
    },
  },
  'baseMeta': {
    'metaTitle': product->.metaTitle,
    'overrideMainHeader': product->.overrideMainHeader,
    'metaDescription': product->.metaDescription,
    'openGraphTitle': product->.openGraphTitle,
    'openGraphDescription': product->.openGraphDescription,
    'openGraphImage': product->.openGraphImage ${image},
    'twitterTitle': product->.twitterTitle,
    'twitterDescription': product->.twitterDescription,
    'twitterImage': product->.twitterImage ${image},
    'allowIndexing': product->.allowIndexing,
  }
}`

const collection = groq`{
  _id,
  title,
  searchTags,
  description,
  trimDescriptionOnMobile,
  trimDescriptionOnTablet,
  trimDescriptionOnDesktop,
  showThinBanner,
  discountThemeColor,
  hideStrikeoutPrice,
  hideRecurringPrice,
  thinBannerImage ${image},
  thinBannerHeadline,
  thinBannerHeadlineColor {
    hex,
  },
  metaDescription,
  metaTitle,
  showThickBanner,
  thickBannerHeadline,
  thickBannerImage ${image},
  thickBannerText,
  thickBannerLink[0] ${link},
  thickBannerBackgroundColor {
    hex,
  },
  contentPaddingLeft,
  PLPTiles[] {
      _type,
      backgroundColor {
        hex,
      },
      textColor {
        hex,
      },
      layout,
      startIndex,
      collection[] -> { 
        title,
        slug {
          current
        }
      },
      subCollection[] -> { 
        title,
        slug {
          current
        }
      },
      autoDiscountOneTimeText,
      tabletStartIndex,
      headline,
      plpCalloutBody,
      backgroundImage ${image},
      plpCalloutFooter,
      subText,
      link[0] ${link},
      linkColor{
        hex,
      },
      showOnDesktop,
      showOnMobile,
      showOnExtraSection
    },
  products[] -> {
    productId
  },
  extra_section_name,
  extra_section_products[] -> {
    productId
  },
  'slug': slug.current,
  'background': heroBannerBackground[0] ${backgroundColorStyle},
  ...*[_type == 'plp'][0] {
    collectionGroupHeader,
    collectionStickyHeader,
    'menu': collectionMenu[] {
      ...,
      collection -> {
        _id,
        title,
        '_type': 'internalLink',
        'reference': {
          _type,
          'slug': slug.current,
        },
      }
    },
    filters[] {
      _key,
      title,
      'slug': slug.current,
      collections[]-> {
        _id,
        title,
        'slug': slug.current,
      },
    },
  },
  openGraphImage ${image},
  allowIndexing,
  twitterImage ${image},
}`

const collectionForPreview = groq`{
  products[@->isEnabled == true][]-> {
    subscriptionV2,
    subscriptionType,
    altImageForABTest,
    unitPrice,
    subscriptionPlpDescription,
    _type,
    _id,
    soldOutSettings,
    productId,
    isEnabled,
    title,
    'slug': slug.current,
    isYotpoDisabled,
    isPlpStarRatingHidden,
    disableQuickAdd,
    showPLPReviews,
    plpDescriptor,
    enablePLPClick,
    sellingPlans[],
    subscription->,
    price,
    thumbnailImage {
      asset-> {
        assetId,
        url,
        _id,
        altText,
        originalFilename,
        metadata {
          dimensions {
          width,
          aspectRatio,
          height
          }
        }
      },
    },
    mediaGallery[] {
      _key,
      _type,
      _type == 'imageAsset' => {
        image {
          asset-> {
            assetId,
            altText,
            url,
            _id,
            originalFilename,
            metadata {
              dimensions {
              width,
              aspectRatio,
              height
              }
            }
          },
        },
        isHoverImage
      }
    },
    badges[]-> {
      _id,
      label,
      _type,
      background {
        cssGradient,
        ...reference-> {
          ...style[0] {
            color {
            hex,
            rgb
            }
          }
        },
      },
    },
    options,
    'plpSwatch': plpSwatch {
      enable,
      options,
      options2,
      multiLineArrangement2,
      optionSettings,
      optionValueSettings,
      component,
      component2,
      showDescription,
      showDescription2,
      showColorOnlyOnPlp,
      swatchItems[] {
        _type,
        _key,
        option,
        swatchIcon {
          asset->,
        },
        colorStack {
          ...reference-> {
            ...style[0]
          },
        },
        color {
          hex,
          rgb
        }
      },
      swatchItems2[] {
        _type,
        _key,
        option,
        swatchIcon {
          asset->,
        },
        colorStack {
          ...reference-> {
            ...style[0]
          },
        },
        color {
          hex,
          rgb
        }
      }
    },
    compareOverlay,
    priceNotation {
      notations[] {
        enable,
        countries[]-> { countryCode },
        createNotation[] { ... },
        atcButtonPrice
      }
    },
    'collections': *[_type == 'collection' && references(^._id)] {
      _id,
      title,
      'slug': slug.current,
    },
    _type == 'product' => {
      productId,
      cents,
      shopifyImage {
        asset-> {
          assetId,
          url,
          _id,
          originalFilename,
          metadata {
            dimensions {
            width,
            aspectRatio,
            height
            }
          }
        },
      },
      variants[] {
        mediaGallery[] {
          _type,
          _type == 'imageAsset' => {
            image {
              asset-> {
                assetId,
                altText,
                url,
                _id,
                originalFilename,
                metadata {
                  dimensions {
                  width,
                  aspectRatio,
                  height
                  }
                }
              },
            },
            isHoverImage
          }
        },
        soldOutSettings,
        cents,
        price,
        disableForOneTime,
        recurringPrice,
        showOnCollection,
        positionOnCollection,
        disableForSubscription,
        ...productVariant-> {
          cents,
          price,
          variantTitle,
          altImageForABTest,
          variantId,
          title,
          soldOutSettings,
          recurringPrice,
          sku,
          sellingPlans,
          qtySubtitle,
          productVariant,
          productTitle,
          productId,
          price,
          option1,
          option2,
          option3,
          mediaGallery,
          media,
        }
      },
    },
  },
}`

const page = groq`{
  ...,
  title,
  isAnchorNavEnabled,
  isNoBanner,
  isNoNavigation,
  promobar {
    ...,
    background ${backgroundColorStyle},
    textColor ${backgroundColorStyle},
    cta {
      ...,
      scrollTo -> {
        _id,
        hash
      }
    },
  },
  'slug': slug.current,
  'modules': contentModules[] ${globalModules},
  openGraphImage ${image},
  twitterImage ${image},
  allowIndexing,
  giftProduct-> {
    title,
    cents,
    productId,
    variants[] {
      ...,
      siloImage ${siloImage},
      ...productVariant-> {
        ...,
      }
    },
    options,
  },
}`

const article = groq`{
  ...,
  'slug': slug.current,
  tags[]-> {
    ...,
    'slug': slug.current,
  },
  featuredImage ${image},
  content[] ${richText},
  'modules': contentModules[] ${globalModules},
  'cta': *[_type == 'blog'][0].blogCta ${advancedCta},
}`

const articleTag = groq`{
  ...,
  'slug': slug.current,
  'link': {
    '_type': 'internalLink',
    'reference': {
      '_type': 'articleTag',
      'slug': slug.current,
    },
  },
  openGraphImage ${image},
  twitterImage ${image},
  allowIndexing,
}`

const articleCard = groq`{
  _id,
  title,
  'slug': slug.current,
  'primaryTag': tags[0]-> ${articleTag},
  tags[]-> ${articleTag},
  featuredImage ${image},
  excerpt,
  isExcerptSurfaced,
  'link': {
    '_type': 'internalLink',
    'reference': {
      '_type': 'article',
      'slug': slug.current,
    },
  },
}`

const editorialModule = (slice) => {
  return groq`select(
    defined(filter) => *[_type == 'article' && publishedAt < now() && references(^.filter._ref)] | order(publishedAt desc)[${slice}] ${articleCard},
    !defined(filter) => *[_type == 'article' && publishedAt < now()] | order(publishedAt desc)[${slice}] ${articleCard},
  )`
}

const articleQuickNav = groq`{
  ...,
  articles[]-> {
    _id,
    title,
    'link': {
      '_type': 'internalLink',
      'reference': {
        '_type': 'article',
        'slug': slug.current,
      },
    },
  },
}`

const blogHome = groq`{
  ...,
  blogCta ${advancedCta},
  intro[] ${justLinksBlock},
  articleQuickNav ${articleQuickNav},
  filters[]-> ${articleTag},
  editorialModules[] {
    ...,
    filter-> ${articleTag},
    _type == 'fullWidthFeatureThreeUp' => {
      'articles': ${editorialModule('0...4')}
    },
    _type == 'halfWidthFeatureFourUp' => {
      'articles': ${editorialModule('0...5')}
    },
    _type == 'threeUp' => {
      'articles': ${editorialModule('0...3')}
    },
    slots[] {
      ...,
      _type == 'pinned' => {
        article-> ${articleCard},
      },
      _type == 'dynamic' => {
        filter-> ${articleTag},
        'article': ${editorialModule(0)},
      },
    },
  },
  openGraphImage ${image},
  twitterImage ${image},
  allowIndexing,
}`

const productFilter = (preview = false) => groq`
  _type == 'product' &&
  defined(slug.current) &&
  wasDeleted != true &&
  !('hidden' in tags)
  ${preview ? `` : `&& isEnabled == true`}
`

const pageFilter = (preview = false) => groq`
  _type == 'page' &&
  defined(slug.current) &&
  ${preview ? `` : `isArchived != true`}
`

const productLanderFilter = (preview = false) => groq`
  _type == 'productLander' &&
  defined(slug.current) &&
  product->.wasDeleted != true
  ${preview ? `` : `&& product->.isEnabled == true && isEnabled == true`}
`
const Search = groq`*[_type == 'search'][0]{
  title,
  products[@->isEnabled == true][]->{
    slug{
    current
    }
  }
}`
const shared = groq`
  'promoBar': ${promoBar},
  'promoConfig': ${promoConfig},
  'navigation': ${navigation},
  'footer': ${footer},
  'globals': ${globals},
  'cookieNotice': ${cookieNotice},
  'cart': ${cart},
  'plps': ${plps},
  'searchConfig':${Search}
`

export async function getProductSlugs() {
  const all = await getSanityClient().fetch(groq`*[${productFilter()}][].slug.current`)
  return all
}

export async function getPageSlugs() {
  const all = await getSanityClient().fetch(groq`*[${pageFilter()}][].slug.current`)
  return all
}

export async function getProductLanderPaths() {
  const all = await getSanityClient().fetch(groq`*[${productLanderFilter()}] {
    'productSlug': product->.slug.current,
    'slug': slug.current,
  }`)
  return all
}

export async function getBlogListingSlugs() {
  const slugs = await getSanityClient().fetch(
    groq`*[_type == 'articleTag' && defined(slug.current)][].slug.current`
  )

  return ['all', ...slugs]
}

export async function getPaginatedBlogListingSlugs() {
  const data = await getSanityClient().fetch(
    groq`{
      'all': *[_type == 'article' && publishedAt < now()],
      'tags': *[_type == 'articleTag' && defined(slug.current)] {
        'slug': slug.current,
        'articles': *[_type == 'article' && publishedAt < now() && references(^._id)][]._id
      },
    }`
  )

  const paths = [
    ...chunk(data.all, ARTICLES_PER_PAGE).map((_, i) => ({
      params: {
        slug: 'all',
        page: (i + 1).toString(),
      },
    })),
    ...data.tags
      .filter(({ articles }) => articles.length)
      .map(({ slug, articles }) =>
        chunk(articles, ARTICLES_PER_PAGE).map((_, i) => ({
          params: {
            slug,
            page: (i + 1).toString(),
          },
        }))
      ),
  ]
    .flat()
    .filter((path) => path.params.page !== '1')

  return paths
}

export async function getArticleSlugs() {
  const slugs = await getSanityClient().fetch(
    groq`*[_type == 'article' && defined(slug.current) && publishedAt < now()][].slug.current`
  )

  return slugs
}

export async function getAllDocSlugs(type) {
  const all = await getSanityClient().fetch(
    groq`*[_type == '${type}' && defined(slug.current)][].slug.current`
  )

  const hiddenSlugList = ['product-all-hidden']
  const allVisible = all.filter(item => !hiddenSlugList.includes(item.toLowerCase()))

  return allVisible
}

export async function getAllDocSlugsWithIndexing(type) {
  const all = await getSanityClient().fetch(
    groq`*[_type == '${type}' && defined(slug.current)] {
      "slug": slug.current,
      "allowIndexing": allowIndexing
    }`
  )
  return all
}

export async function getSharedData() {
  return await getSanityClient().fetch(groq`{
    ${shared}
  }`)
}

export async function getBottomlineForProducts(products) {
  if (!products || !products.length) return Promise.resolve()

  const bottomlines = await Promise.all(
    products.map(({ productId }) =>
      getProductReviewAggregate({
        productId,
      })
    )
  )

  products.forEach((product, i) => {
    product.bottomline = bottomlines[i]
  })
}

export async function getBottomlineForCartCrossellProducts(products) {
  if (!products || !products.length) return Promise.resolve()

  const bottomlines = await Promise.all(
    products.map(({ variant }) => {
      return getProductReviewAggregate({
        productId: variant.productId,
      })
    })
  )

  products.forEach((product, i) => {
    product.product.bottomline = bottomlines[i]
    product.product.isBottomlineVisible = !(
      product.product.isYotpoDisabled || product.product.isPlpStarRatingHidden
    )
  })
}

export async function getPage(makeQuery, preview) {
  const query = groq`{
    ${shared},
    'page': ${makeQuery(page)},
  }`

  const data = await getSanityClient(preview)
    .fetch(query)
    .catch((err) => {
      console.error('Error while fetching page:\n', query, '\n', err)
      return null
    })

  const quickAddProducts = data?.page?.modules
    ?.filter((module) =>
      ['quickAddSubscriptionKit', 'advProductCarousel', 'lpHero', 'productHero'].includes(
        module._type
      )
    )
    .map(({ product, products }) => product || products)
    .flat()
    .filter((product) => {
      product.isBottomlineVisible = true
      return !product.isYotpoDisabled
    })

  await getBottomlineForProducts(quickAddProducts)
  return data
}

export async function getProduct(slug, preview) {
  const data = await getSanityClient(preview).fetch(groq`{
    ${shared},
    'product': *[
      slug.current == '${slug}' &&
      ${productFilter(preview?.active)}
    ] | order(_updatedAt desc)[0] {
      ${pdpProjection},
    }
  }`)

  if (!data.product) return data

  await getProductReviewAggregate({
    productId: data.product.productId,
  })

  data.product.bottomline = await getProductReviewAggregate({
    productId: data.product.productId,
  })

  return data
}

let productsByIdCache = [];

export async function getProductById(id) {
  if (productsByIdCache[id]) {
    return productsByIdCache[id]
  }
  const data = await getSanityClient().fetch(groq`{
    'product': *[
      _id == '${id}'
    ][0] {
      ${pdpProjection},
    }
  }`)

  if (!data.product) return null

  // @ts-ignore
  productsByIdCache[id] = data

  return productsByIdCache[id]
}

export async function getProductLander(slug, preview) {
  const data = await getSanityClient(preview).fetch(groq`{
    ${shared},
    'product': *[
      slug.current == '${slug}' &&
      ${productLanderFilter(preview?.active)}
    ] | order(_updatedAt desc)[0] ${productLander}
  }`)

  if (!data.product) return data

  return data
}

export async function getProductLanderProductSlug(slug, preview) {
  const data = await getSanityClient(preview).fetch(
    groq`*[
      slug.current == '${slug}' &&
      ${productLanderFilter(preview?.active)}
    ] | order(_updatedAt desc)[0] {
      'productSlug': product->.slug.current,
    }`
  )

  return data
}

export async function getCollection(slug, preview) {
  const data = await getSanityClient(preview).fetch(groq`{
    ${shared},
    'collection': *[_type == 'collection' && slug.current == '${slug}'] | order(_updatedAt desc)[0] ${collection}
  }`)
  if (!data.collection) return data
  await getBottomlineForProducts(data.collection.products)

  return data
}

export async function getCollectionForPreview(slug, preview) {
  if (!preview?.active) return null
  const data = await getSanityClient(preview).fetch(groq`{
    'collection': *[_type == 'collection' && slug.current == '${slug}'] | order(_updatedAt desc)[0] ${collectionForPreview}
  }`)
  if (!data.collection) return data
  const updatedProducts: any = []
  data?.collection?.products?.map((product) => {
    const variants = product?.variants?.filter((variant) => variant?.showOnCollection)
    const variantsToShowBefore =
      variants?.filter((variant) => variant?.positionOnCollection === '1') ?? []
    const variantsToShowAfter =
      variants?.filter(
        (variant) => !variant?.positionOnCollection || variant?.positionOnCollection === '2'
      ) ?? []

    if (variantsToShowBefore?.length > 0) {
      variantsToShowBefore?.forEach((variant) => {
        updatedProducts.push({
          ...product,
          ...variant,
          collections: product.collections,
          type: 'productVariant',
        })
      })
    }
    updatedProducts.push(product)
    if (variantsToShowAfter?.length > 0) {
      variantsToShowAfter?.forEach((variant) => {
        updatedProducts.push({
          ...product,
          ...variant,
          collections: product.collections,
          type: 'productVariant',
        })
      })
    }
  })
  await getBottomlineForProducts(updatedProducts)

  return {
    ...data,
    collection: {
      ...data.collection,
      products: updatedProducts,
    },
  }
}

export async function getArticle(slug, preview) {
  const data = await getSanityClient(preview).fetch(groq`{
    ${shared},
    'all': *[_type == 'article' && publishedAt < now() && slug.current != '${slug}'] | order(publishedAt desc) ${articleCard},
    'article': *[
      _type == 'article' &&
      slug.current == '${slug}'
      ${preview?.active ? `` : `&& publishedAt < now()`}
    ] | order(_updatedAt desc)[0] ${article},
  }`)

  return data
}

export async function getBlogHome(preview) {
  const data = await getSanityClient(preview).fetch(groq`{
    ${shared},
    'blog': *[_type == 'blog'] | order(_updatedAt desc)[0] ${blogHome},
  }`)

  return data
}

export async function getBlogListing(slug, preview) {
  const data = await getSanityClient(preview).fetch(groq`{
    ${shared},
    'blog': *[_type == 'blog'] | order(_updatedAt desc)[0] {
      filters[]-> ${articleTag},
      articleQuickNav ${articleQuickNav},
      'articles': *[_type == 'article' && publishedAt < now()] | order(publishedAt desc) ${articleCard},
      'tag': *[_type == 'articleTag' && slug.current == '${slug}'][0] { title, ... },
    },
  }`)

  return data
}
