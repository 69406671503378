import DesktopNavigation from 'components/Navigation/Desktop/DesktopNavigation'
import MobileNavigation from 'components/Navigation/Mobile/MobileNavigation'
import getColorStyle from 'lib/getColorStyle'
import { useRouter } from 'next/router'
import linkResolver from 'lib/linkResolver'
import { useEffect, useMemo } from 'react'
import { useDiscountFromURL, useStore } from 'context/globalStore'

export default function Navigation({
  homepageCta,
  globals,
  bgColorConfig,
  searchConfig,
  isBannerHidden,
  isNoNavigation,
  globalNavigation,
  globalNavigationOTC
}) {
  const { asPath: currentPath } = useRouter()
  const bgColorStyle = getBgColorStyle(bgColorConfig, currentPath)
  const router = useRouter()
  const { setDiscountFromURL } = useDiscountFromURL()
  const { store } = useStore()
  const { countryCode } = store
  const storeNavigation = useMemo( () => {
    const mergedNavigation = { ...globalNavigation };
    if (countryCode !== 'US') {
      Object.keys(globalNavigationOTC).forEach((key) => {
        if (globalNavigationOTC[key] !== null) {
          mergedNavigation[key] = globalNavigationOTC[key];
        }
      });
    }
    return mergedNavigation
  }, [countryCode] )


  useEffect(() => {
    const discount = router.query?.discount
    if (discount) setDiscountFromURL(discount)
  }, [router.query, setDiscountFromURL])

  return (
    <>
      <DesktopNavigation
        globalNavigation={storeNavigation}
        homepageCta={homepageCta}
        bgColorStyle={bgColorStyle}
        globals={globals}
        searchConfig={searchConfig}
        isBannerHidden
        isNoNavigation={isNoNavigation}
      />
      <MobileNavigation
        globalNavigation={storeNavigation}
        bgColorStyle={bgColorStyle}
        globals={globals}
        searchConfig={searchConfig}
        isBannerHidden
        isNoNavigation={isNoNavigation}
      />
    </>
  )
}

function getBgColorStyle(config, path) {
  const defaultBg = { backgroundColor: '#ffffff' }

  if (!config) {
    return defaultBg
  }

  config = config.map((x) => ({
    link: linkResolver({ ...x.link, _type: 'internalLink' }),
    color: x.color,
  }))
  const bg = config.find((x) => path.includes(x.link))

  if (!bg) {
    return defaultBg
  } else {
    return { backgroundColor: getColorStyle(bg.color) }
  }
}
