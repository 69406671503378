import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router'
import { usePromoConfig } from 'hooks/usePromoConfig'
import BlockContent from '@sanity/block-content-to-react'
import promoBoxSerializer from 'serializers/promoBoxSerializer'
import isBannerAvailableOnPageLevel from '../helpers/isBannerAvailableOnPageLevel'
import { useStore } from 'context/globalStore'

export default function CartBanner() {
  const [cartPromoFromStorage, setCartPromoFromStorage] = useState<string | null>(null);
  const { store } = useStore()

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const promoData = localStorage.getItem('promoData');
      setCartPromoFromStorage(promoData);
    }
  }, []);

  const promoConfig = usePromoConfig() as {
    cartBanner?: any;
    promoTheme?: any;
    enablePromoOnPageLevel?: boolean;
    pagesToApplyPromo?: any;
    enablePromoForCustomerFromActivePage?: boolean;
  } || cartPromoFromStorage;
  const router = useRouter()

  if (promoConfig?.cartBanner && promoConfig?.enablePromoOnPageLevel && promoConfig?.enablePromoForCustomerFromActivePage && store.countryCode === 'US') {
    return (
      <div
        className="w-full z-4 text-navy-blue text-center text-13 py-8"
        style={{ backgroundColor: promoConfig?.promoTheme?.cartBannerColor?.hex ?? '#003057' }}
      >
        <BlockContent
          blocks={promoConfig.cartBanner}
          serializers={promoBoxSerializer}
          renderContainerOnSingleChild
          className="max-w-255 s:max-w-full mx-auto"
        />
      </div>
    )

  } else if (isBannerAvailableOnPageLevel(promoConfig, router) && store.countryCode === 'US') {
    return (
      <div
        className="w-full z-4 text-navy-blue text-center text-13 py-8"
        style={{ backgroundColor: promoConfig?.promoTheme?.cartBannerColor?.hex ?? '#003057' }}
      >
        <BlockContent
          blocks={promoConfig.cartBanner}
          serializers={promoBoxSerializer}
          renderContainerOnSingleChild
          className="max-w-255 s:max-w-full mx-auto"
        />
      </div>
    )
  } else if (promoConfig?.cartBanner && !promoConfig?.enablePromoOnPageLevel && store.countryCode === 'US') {
    return (
      <div
        className="w-full z-4 text-navy-blue text-center text-13 py-8"
        style={{ backgroundColor: promoConfig?.promoTheme?.cartBannerColor?.hex ?? '#003057' }}
      >
        <BlockContent
          blocks={promoConfig.cartBanner}
          serializers={promoBoxSerializer}
          renderContainerOnSingleChild
          className="max-w-255 s:max-w-full mx-auto"
        />
      </div>
    )
  }
}