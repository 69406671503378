import { useEffect, useState } from 'react'
import { Accordion } from '@reach/accordion'
import VisualSubmenu from './DesktopVisualSubmenu'
import TextualSubmenu from './DesktopTextualSubmenu'
import MenuItem from './DesktopMenuItem'
import ColoredMenuItem from './DesktopColoredMenuItem'
import { on } from 'martha'
import { useRouter } from 'next/router'

export default function DesktopPrimaryMenu({ items }) {
  const [index, setIndex] = useState(null)
  const router = useRouter()

  useEffect(() => {
    const offClick = on(document, 'click', (ev) => {
      if (!ev.target.closest('[data-reach-accordion-item]')) {
        setIndex(null)
      }
    })

    const offKeyup = on(window, 'keyup', ({ key }) => {
      if (key === 'Escape') {
        setIndex(null)
      }
    })

    const handleChange = () => setIndex(null)
    router.events.on('routeChangeStart', handleChange)
    router.events.on('hashChangeStart', handleChange)

    return () => {
      router.events.off('routeChangeStart', handleChange)
      router.events.off('hashChangeStart', handleChange)
      offClick()
      offKeyup()
    }
  }, [])

  return (
    <div className="h-full">
      <Accordion
        className="h-full flex xl:space-x-10 -ml-15"
        as="ul"
        index={index}
        onChange={(changed) => setIndex(index === changed ? null : changed)}
      >
        {items?.map((item) => {
          switch (item._type) {
            case 'desktopColumnSubmenu':
              return <VisualSubmenu key={item._key} {...item} />
            case 'desktopTextualSubmenu':
              return <TextualSubmenu key={item._key} {...item} />
            case 'internalLink':
            case 'externalLink':
              return (
                <MenuItem key={item._key} link={item}>
                  {item.title}
                </MenuItem>
              )
            case 'coloredLink':
              return <ColoredMenuItem key={item._key} {...item} />
          }
        })}
      </Accordion>
    </div>
  )
}
