import useMedia from 'use-media'
import { screens } from 'lib/constants'

export default function useCollectionImage(subscriptionPLPEnabled = false) {
  const isScreenSmall = useMedia({ maxWidth: screens.s })
  const isScreen2k = useMedia({ minWidth: screens['2k'] })


  if (isScreenSmall) {
    return {
      height: subscriptionPLPEnabled ? 400 : 250,
      width: subscriptionPLPEnabled ? 400 : 250,
    }
  }
  if (isScreen2k) {
    return {
      height: subscriptionPLPEnabled ? 750 : 600,
      width: subscriptionPLPEnabled ? 750 : 600,
    }
  }

  return {
    height: subscriptionPLPEnabled ? 550 : 400,
    width: subscriptionPLPEnabled ? 550 : 400,
  }
}
